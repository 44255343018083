import dayjs from './dayjs';

const tokenizeCc = async ({ ccName, ccNumber, ccCode, ccExpiration }) =>
  new Promise((resolve, reject) => {
    window.Iugu.setAccountID('F914E453F1EC4575BA55A83BE6C86496');
    window.Iugu.setTestMode(process.env.GATSBY_ENV !== 'production');

    const adaptedCcNumber = ccNumber.replace(/ /g, '');
    const day = dayjs(ccExpiration, 'MM/YY');
    const ccFirstName = ccName.slice(0, ccName.indexOf(' '));
    const ccSurName = ccName.slice(ccName.indexOf(' ') + 1);

    const iuguCardData = window.Iugu.CreditCard(
      adaptedCcNumber,
      ccExpiration.split('/')[0],
      `${day.year()}`,
      ccFirstName,
      ccSurName,
      ccCode,
    );

    window.Iugu.createPaymentToken(iuguCardData, (res) => {
      if (res.errors) {
        reject(res.errors);
      } else {
        resolve(res);
      }
    });
  });

export default tokenizeCc;
