import React from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import CartButton from '../CartButton';
import './CartSelectPOS.styles.scss';
import Select from '../Select';
import SelectPOS from '../SelectPOS';

const validationSchema = Yup.object().shape({
  posGroup: Yup.string().required('Campo obrigatório'),
  posModel: Yup.number(),
});

const CartPOS = ({
  onBack,
  onFormSubmit,
  posGroup,
  posModel,
  handlePosPlan,
  disabled,
}) => {
  const formik = useFormik({
    initialValues: {
      posGroup,
      posModel,
    },
    validationSchema,
    onSubmit: onFormSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const { values, setFieldValue, handleSubmit, isSubmitting } = formik;

  const handlePosGroupChange = (e) => {
    setFieldValue('posGroup', e.target.value);
  };

  const handlePosChange = (e) => {
    setFieldValue('posModel', e.currentTarget.value);
  };

  const options = [
    {
      value: 'getnet',
      label: 'Getnet',
    },
    {
      value: 'pagseguro',
      label: 'Pagseguro',
    },
    {
      value: 'stone',
      label: 'Stone',
    },
    {
      value: 'cielo',
      label: 'Cielo',
    },
    {
      value: 'rede',
      label: 'Rede',
    },
  ];

  return (
    <>
      <div className="cartSelectPos">
        <div className="cartSelectPos__containerLeft">
          <p>
            Vamos confirmar qual é a sua maquininha para termos certeza que o
            sistema funciona.
          </p>
          <span className="cartSelectPos__selectorLabel">
            Selecione a marca da sua maquininha
          </span>
          <Select
            selected={values.posGroup}
            onChange={handlePosGroupChange}
            options={options}
            disabled={disabled}
          />
          <button
            onClick={handlePosPlan}
            type="button"
            className="cartSelectPos__helpLink is-desktop"
            disabled={disabled}
          >
            Não achei a minha maquininha. Ver oferta!
          </button>
        </div>
        <div className="cartSelectPos__containerRight">
          <SelectPOS
            group={values.posGroup}
            selected={values.posModel}
            onChange={handlePosChange}
            disabled={disabled}
          />
          <button
            onClick={handlePosPlan}
            type="button"
            className="cartSelectPos__helpLink is-mobile"
            disabled={disabled}
          >
            Não achei a minha maquininha. Ver oferta!
          </button>
        </div>
      </div>
      <div className="cartForm__buttonsContainer">
        <CartButton outlined onClick={onBack(values)}>
          ANTERIOR
        </CartButton>
        <CartButton onClick={handleSubmit} disabled={isSubmitting} large>
          PRÓXIMO
        </CartButton>
      </div>
    </>
  );
};

CartPOS.defaultProps = {
  posGroup: 'getnet',
  posModel: undefined,
  disabled: false,
};

CartPOS.propTypes = {
  onBack: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  handlePosPlan: PropTypes.func.isRequired,
  posGroup: PropTypes.string,
  posModel: PropTypes.number,
  disabled: PropTypes.bool,
};

export default CartPOS;
