import React, { useMemo, useContext } from 'react';

import PropTypes from 'prop-types';

import { CartContext } from '../../contexts/cartContext';
import currencyFormatter from '../../utils/currencyFormatter';
import { normalizeCurrency } from '../../utils/normalizers';
import CartPlanDescription from '../CartPlanDescription';
import CartPlanTooltip from '../CartPlanTooltip';
import Toggler from '../Toggler/Toggler';
import './CartPlan.styles.scss';

const CartPlan = ({
  plan,
  planPrice,
  planQuantity,
  planChange,
  fiscalPrice,
  handlePosChange,
  posPrice,
  onQuantityIncrease,
  onQuantityDecrease,
  onQuantityChange,
  disabled,
}) => {
  const { isPlanWith } = useContext(CartContext);

  const planCurrencyInitialPrice = currencyFormatter.format(
    planPrice * planQuantity + (isPlanWith.POS ? posPrice * planQuantity : 0),
  );

  const fiscalPriceLabel = useMemo(
    () => normalizeCurrency(fiscalPrice),
    [fiscalPrice],
  );

  const RenderPOSButton = () => {
    return (
      !plan.addon && (
        <div className="cartPlan__container align-center no-bg">
          <button
            type="button"
            className={`cartPlan__btn${disabled ? 'Disabled' : ''}`}
            onClick={handlePosChange}
            disabled={disabled}
          >
            Quero uma maquininha
          </button>
        </div>
      )
    );
  };

  if (!plan) {
    return null;
  }

  return (
    <div className="cartPlan">
      <div
        className={`cartPlan__quantityRow ${
          !isPlanWith.POS && 'cartPlan__quantityRow__columns'
        }`}
      >
        <div className="cartPlan__container">
          <span className="cartPlan__subtitle">
            {plan.name}
            <CartPlanTooltip
              img="https://assets.eye.com.br/info.svg"
              alt="info"
              tooltip="Você pode assinar um usuário para cada vendedor ou maquininha que tiver."
              small
              className="cartPlan__subtitle__tooltip"
            />
          </span>
          <div className="cartPlan__planDetailRow">
            <div className="cartPlan__planDetailRow__planDetailPart">
              <span className="cartPlan__planDetail">Valor unt.</span>
              <span className="cartPlan__planTotalPriceMonth">
                <span className="cartPlan__planTotalPrice">
                  {planCurrencyInitialPrice}
                </span>
                {' /mês'}
              </span>
            </div>
            <div className="cartPlan__planDetailRow__planDetailSeparator" />
            <div className="cartPlan__planDetailRow__planDetailPart__quantity">
              <span className="cartPlan__planDetail">Qtd. de usuários</span>
              <div className="cartPlan__planDetailRow__planDetailPart__quantityInput">
                <div
                  className={`cartPlan__planDetailRow__planDetailPart__quantityInput__quantityButton${
                    disabled ? '__quantityDisabled' : ''
                  }`}
                  onClick={onQuantityDecrease}
                  role="presentation"
                >
                  -
                </div>
                <input
                  type="text"
                  value={planQuantity}
                  className="cartPlan__planDetailRow__planDetailPart__quantityInput__quantityDisplay"
                  onChange={onQuantityChange}
                  disabled={disabled}
                />
                <div
                  className={`cartPlan__planDetailRow__planDetailPart__quantityInput__quantityButton${
                    disabled ? '__quantityDisabled' : ''
                  }`}
                  onClick={onQuantityIncrease}
                  role="presentation"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div
            className={`cartPlan__footer emit-nfce ${
              isPlanWith.fiscal && 'active'
            }`}
          >
            <p className="nfce-question">
              Quero emitir NFC-e ({`+R$ ${fiscalPriceLabel}`})
            </p>
            <Toggler
              handleChange={planChange}
              initialState={isPlanWith.fiscal}
              disabled={disabled}
            />
          </div>
        </div>
        <RenderPOSButton />
      </div>
      <div className="cartPlan__planValuesSeparator" />
      <CartPlanDescription plan={plan} />
    </div>
  );
};

CartPlan.defaultProps = {
  fiscalPrice: undefined,
  disabled: false,
};

CartPlan.propTypes = {
  plan: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    baseName: PropTypes.string,
    value: PropTypes.number.isRequired,
    addon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      tooltip: PropTypes.string.isRequired,
      quantifier: PropTypes.string,
      productName: PropTypes.string,
    }),
  }).isRequired,
  planPrice: PropTypes.number.isRequired,
  planQuantity: PropTypes.number.isRequired,
  planChange: PropTypes.func.isRequired,
  fiscalPrice: PropTypes.number,
  handlePosChange: PropTypes.func.isRequired,
  posPrice: PropTypes.number.isRequired,
  onQuantityIncrease: PropTypes.func.isRequired,
  onQuantityDecrease: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CartPlan;
