import React from 'react';

import { navigate } from 'gatsby';

import { PORTAL_URL } from '../../utils/constants';
import CartButton from '../CartButton';

import './CartOutro.styles.scss';

const CartOutro = () => {
  return (
    <div className="cartOutro">
      <div className="cartOutro__mainMessageContainer is-mobile">
        <div className="cartOutro__mainMessageContainer__image" />
        <span className="cartOutro__mainMessageContainer__text">
          Parabéns,
          <br /> você começou a simplificar a sua forma de vender.
        </span>
      </div>
      <div className="cartOutro__content">
        <span className="cartOutro__content__contentTitle">
          Pedido realizado com sucesso!
        </span>
        <p className="cartOutro__content__contentText">
          Bem vindo a Eye. A partir de agora você vai conseguir otimizar ainda
          mais as suas vendas sem precisar de um computador para vender e ainda
          ter todos os dados do seu negócio na sua mão.
        </p>
        <div className="cartOutro__content__box">
          <p className="cartOutro__content__contentText highlight">
            Entraremos em contato por WhatsApp para realizar seu atendimento.
          </p>
          <p className="cartOutro__content__contentText">
            <strong className="highlight">Verifique seu email,</strong> confira
            os próximos passos para configurar a sua conta e comece a usar o
            sistema Eye para alavancar suas vendas o quanto antes.
          </p>
          <p className="cartOutro__content__contentText">
            Se tiver qualquer dúvida em relação ao seu pagamento ou fatura, é só
            entrar em contato com o nosso financeiro
          </p>
          <div className="cartOutro__content__eye-contact">
            <strong className="cartOutro__content__eye-contact__heading">
              Financeiro Eye
            </strong>
            <span className="cartOutro__content__eye-contact__type">
              (WhatsApp)
            </span>
            <strong className="cartOutro__content__eye-contact__number">
              (48)9.9124-7023
            </strong>
          </div>
          <div className="cartOutro__content__buttonWrapper">
            <CartButton onClick={() => navigate(PORTAL_URL)} large>
              FINALIZAR
            </CartButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartOutro;
