/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';

import PropTypes from 'prop-types';
import './CartCheckbox.styles.scss';

const CartCheckbox = ({ inputId, label, checked, onChange, disabled }) => {
  const handleClick = () => onChange(!checked);

  return (
    <label htmlFor={inputId} className="cartCheckbox">
      <input
        type="checkbox"
        id={inputId}
        name={inputId}
        checked={checked}
        onChange={handleClick}
        className="cartCheckbox__input"
        disabled={disabled}
      />
      <div className="cartCheckbox__checkbox">
        {checked && <div className="cartCheckbox__checkbox__checked" />}
      </div>
      <span className="cartCheckbox__label">{label}</span>
    </label>
  );
};

CartCheckbox.defaultProps = {
  disabled: false,
};

CartCheckbox.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CartCheckbox;
