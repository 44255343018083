import React, { useEffect } from 'react';

import cx from 'clsx';
import Payment from 'payment';
import PropTypes from 'prop-types';

import './CreditCard.styles.scss';

const CreditCard = ({
  cvc,
  preview,
  issuer,
  focused,
  locale,
  name,
  placeholders,
  acceptedCards,
  number,
  expiry,
}) => {
  const getOptions = () => {
    const currentIssuer = Payment.fns.cardType(number) || 'unknown';

    let maxLength = 16;

    if (issuer === 'amex') {
      maxLength = 15;
    } else if (issuer === 'dinersclub') {
      maxLength = 14;
    } else if (['hipercard', 'mastercard', 'visa'].includes(issuer)) {
      maxLength = 19;
    }

    return {
      issuer: currentIssuer,
      maxLength,
    };
  };

  const getNumber = () => {
    let maxLength = preview ? 19 : getOptions().maxLength;
    let nextNumber =
      typeof number === 'number'
        ? number.toString()
        : number.replace(/[A-Za-z]| /g, '');

    if (Number.isNaN(parseInt(nextNumber, 10)) && !preview) {
      nextNumber = '';
    }

    if (maxLength > 16) {
      maxLength = nextNumber.length <= 16 ? 16 : maxLength;
    }

    if (nextNumber.length > maxLength) {
      nextNumber = nextNumber.slice(0, maxLength);
    }

    while (nextNumber.length < maxLength) {
      nextNumber += '•';
    }

    if (['amex', 'dinersclub'].includes(issuer)) {
      const format = [0, 4, 10];
      const limit = [4, 6, 5];
      nextNumber = `${nextNumber.substr(
        format[0],
        limit[0],
      )} ${nextNumber.substr(format[1], limit[1])} ${nextNumber.substr(
        format[2],
        limit[2],
      )}`;
    } else if (nextNumber.length > 16) {
      const format = [0, 4, 8, 12];
      const limit = [4, 7];
      nextNumber = `${nextNumber.substr(
        format[0],
        limit[0],
      )} ${nextNumber.substr(format[1], limit[0])} ${nextNumber.substr(
        format[2],
        limit[0],
      )} ${nextNumber.substr(format[3], limit[1])}`;
    } else {
      for (let i = 1; i < maxLength / 4; i += 1) {
        const spaceIndex = i * 4 + (i - 1);
        nextNumber = `${nextNumber.slice(0, spaceIndex)} ${nextNumber.slice(
          spaceIndex,
        )}`;
      }
    }

    return nextNumber;
  };

  const getExpiry = () => {
    const date = typeof expiry === 'number' ? expiry.toString() : expiry;
    let month = '';
    let year = '';

    if (date.includes('/')) {
      [month, year] = date.split('/');
    } else if (date.length) {
      month = date.substr(0, 2);
      year = date.substr(2, 6);
    }

    while (month.length < 2) {
      month += '•';
    }

    if (year.length > 2) {
      year = year.substr(2, 4);
    }

    while (year.length < 2) {
      year += '•';
    }

    return `${month}/${year}`;
  };

  const getIssuer = () => {
    return preview && issuer ? issuer.toLowerCase() : getOptions().issuer;
  };

  useEffect(() => {
    const setCards = () => {
      let newCardArray = [];

      if (acceptedCards.length) {
        Payment.getCardArray().forEach((d) => {
          if (acceptedCards.includes(d.type)) {
            newCardArray.push(d);
          }
        });
      } else {
        newCardArray = newCardArray.concat(Payment.getCardArray());
      }

      Payment.setCardArray(newCardArray);
    };

    setCards();
  }, [acceptedCards]);

  return (
    <div key="Cards" className="rccs">
      <div
        className={cx('rccs__card', `rccs__card--${getIssuer()}`, {
          'rccs__card--flipped': focused === 'cvc' && getIssuer() !== 'amex',
        })}
      >
        <div className="rccs__card--front">
          <div className="rccs__card__background" />
          <div className="rccs__issuer" />
          <div
            className={cx('rccs__cvc__front', {
              'rccs--focused': focused === 'cvc',
            })}
          >
            {cvc}
          </div>
          <div
            className={cx('rccs__number', {
              'rccs__number--large': getNumber().replace(/ /g, '').length > 16,
              'rccs--focused': focused === 'number',
              'rccs--filled': getNumber().substr(0, 1) !== '•',
            })}
          >
            {getNumber()}
          </div>
          <div
            className={cx('rccs__name', {
              'rccs--focused': focused === 'name',
              'rccs--filled': name,
            })}
          >
            {name || placeholders.name}
          </div>
          <div
            className={cx('rccs__expiry', {
              'rccs--focused': focused === 'expiry',
              'rccs--filled': getExpiry().substr(0, 1) !== '•',
            })}
          >
            <div className="rccs__expiry__valid">{locale.valid}</div>
            <div className="rccs__expiry__value">{getExpiry()}</div>
          </div>
          <div className="rccs__chip" />
        </div>
        <div className="rccs__card--back">
          <div className="rccs__card__background" />
          <div className="rccs__stripe" />
          <div className="rccs__signature" />
          <div
            className={cx('rccs__cvc', {
              'rccs--focused': focused === 'cvc',
            })}
          >
            {cvc}
          </div>
          <div className="rccs__issuer" />
        </div>
      </div>
    </div>
  );
};

CreditCard.defaultProps = {
  acceptedCards: [],
  locale: {
    valid: 'valid thru',
  },
  placeholders: {
    name: 'YOUR NAME HERE',
  },
  preview: false,
  focused: undefined,
  issuer: undefined,
};

CreditCard.propTypes = {
  acceptedCards: PropTypes.array,
  cvc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  expiry: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  focused: PropTypes.string,
  issuer: PropTypes.string,
  locale: PropTypes.shape({
    valid: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholders: PropTypes.shape({
    name: PropTypes.string,
  }),
  preview: PropTypes.bool,
};

export default CreditCard;
