import React, { createContext, useState, useMemo, useCallback } from 'react';

import * as PropTypes from 'prop-types';
import { useQueryParam, StringParam } from 'use-query-params';

import { prices } from '../constants/origins';
import { plans } from '../constants/plans';

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [utmSource] = useQueryParam('utm_source', StringParam);
  const [utmMedium] = useQueryParam('utm_medium', StringParam);
  const [utmContent] = useQueryParam('utm_content', StringParam);
  const [utmCampaign] = useQueryParam('utm_campaign', StringParam);
  const [gClid] = useQueryParam('gclid', StringParam);
  const [fbClid] = useQueryParam('fbclid', StringParam);
  const [origin] = useQueryParam('origin', StringParam);
  const [plano] = useQueryParam('plano', StringParam);
  const [leadUuid] = useQueryParam('lead_uuid', StringParam);

  const { pdvPrice, fiscalPrice, posPrice } = prices[origin] || prices.default;

  const planPrice =
    pdvPrice + ([plans.P002, plans.P004].includes(plano) ? fiscalPrice : 0);

  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    planId: plano || undefined,
    origin: origin || undefined,
    utmSource: utmSource || undefined,
    utmContent: utmContent || undefined,
    utmMedium: utmMedium || undefined,
    utmCampaign: utmCampaign || undefined,
    gClid: gClid || undefined,
    fbClid: fbClid || undefined,
    disabledPlanChanges: false,
    disabledLeadChanges: false,
    leadUuid,
    fromLeadUuid: !!leadUuid,
    planQuantity: 1,
    pdvPrice,
    fiscalPrice,
    posPrice,
    planPrice,
    planTotal: planPrice,
  });

  const { planId } = data;

  const patchCart = useCallback(
    ({ step: newStep, data: newData = {} }) => {
      setStep(newStep);
      setData((prev) => ({
        ...prev,
        ...newData,
        planTotal:
          (newData.planPrice || prev.planPrice) *
          (newData.planQuantity || prev.planQuantity),
      }));
    },
    [setStep, setData],
  );
  const patchData = useCallback(
    (newData) => {
      setData((prev) => ({
        ...prev,
        ...newData,
        planTotal:
          (newData.planPrice || prev.planPrice) *
          (newData.planQuantity || prev.planQuantity),
      }));
    },
    [setData],
  );

  const isPlanWith = useMemo(() => {
    return {
      fiscal: [plans.P002, plans.P004].includes(planId),
      POS: [plans.P003, plans.P004].includes(planId),
    };
  }, [planId]);

  return (
    <CartContext.Provider
      value={{ step, data, isPlanWith, patchCart, patchData }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
};

export default CartProvider;
