import { plans } from '../constants/plans';
import { isBrowser } from './isBrowser';

export const syncDataLayer = ({ email, phone, items }) => {
  if (isBrowser) {
    const leadUserQuantity = items.find(
      (item) => item.code === plans.P001,
    ).quantity;

    const filteredItems = items.filter((item) => item.quantity);

    const data = {
      leadEmail: email,
      leadPhone: `+55${phone}`,
      leadItems: items.map((item) => item.code).join(','),
      leadUserQuantity,
      leadSalePrice: filteredItems.reduce((acc, curr) => acc + curr.price, 0),
      leadSaleTotal: filteredItems.reduce(
        (acc, curr) => acc + curr.price * curr.quantity,
        0,
      ),
    };

    window.dataLayer.push(data);
  }
};
