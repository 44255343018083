/* eslint-disable max-len */
import React, { useMemo } from 'react';

import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import { useFormik, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import infoPurple from '../../images/info-purple.svg';

import './Forms.styles.scss';
import {
  normalizeNumber,
  normalizeDocument,
  normalizePhoneNumber,
} from '../../utils/normalizers';
import CartButton from '../CartButton';
import CartCheckbox from '../CartCheckbox';
import CartField from '../CartField';
import HasGetnetSubscriptionModal from '../hasGetnetSubscriptionModal';
import Toggler from '../Toggler/Toggler';

const validationSchema = Yup.object().shape({
  isCompanyDocument: Yup.boolean(),
  name: Yup.string()
    .trim()
    .required('Campo obrigatório')
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s ]*)$/g,
      'Nome pode conter apenas letras.',
    )
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)) (?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      'Preencha com nome e sobrenome.',
    ),
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  phone: Yup.string()
    .matches(
      /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
      'Telefone inválido',
    )
    .required('Campo obrigatório'),
  document: Yup.string()
    .required('Campo obrigatório')
    .when('isCompanyDocument', {
      is: true,
      then: Yup.string().test(
        'validateCnpj',
        'Este não é um CNPJ válido. Verifique e tente novamente.',
        function validateCnpj(value) {
          return cnpjValidator.isValid(value);
        },
      ),
      otherwise: Yup.string().test(
        'validateCPF',
        'Este não é um CPF válido. Verifique e tente novamente.',
        function validateCpf(value) {
          return cpfValidator.isValid(value);
        },
      ),
    }),
});

const CartPersonalDataForm = ({
  initialData,
  isPlanWithFiscal,
  handleToast,
  onFormSubmit,
  onBack,
  disabled,
  getnetPlan,
  handleSetGetnetPlan,
}) => {
  const formik = useFormik({
    initialValues: {
      isCompanyDocument: initialData?.document
        ? normalizeNumber(initialData.document).length === 14
        : true,
      name: initialData?.name || '',
      email: initialData?.email || '',
      phone: normalizePhoneNumber(initialData?.phone) || '',
      document: normalizeDocument(initialData?.document) || '',
      marketingOptin: initialData?.marketingOptin || false,
      acceptedPrivacyPolicy: initialData?.acceptedPrivacyPolicy || false,
    },
    validationSchema,
    onSubmit: (values, formikBag) => {
      onFormSubmit(values, formikBag);
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    handleBlur,
    isSubmitting,
    submitForm,
  } = formik;

  const handleMarketingOptinChange = () =>
    setFieldValue('marketingOptin', !values.marketingOptin);

  const onFieldChange = (field) => (e) => setFieldValue(field, e.target.value);

  const onDocumentTypeChange = () => {
    if (isPlanWithFiscal && values.isCompanyDocument) {
      handleToast(
        'Para emitir NFC-e você precisa de um CNPJ. E não deve conseguir seguir com o cadastro com CPF. ',
        { autoClose: true },
      );
      return false;
    }

    setFieldValue('isCompanyDocument', !values.isCompanyDocument);
    return true;
  };

  const infoType = useMemo(() => {
    return {
      document: {
        label: values.isCompanyDocument ? 'CNPJ' : 'CPF',
        placeholder: values.isCompanyDocument ? 'CNPJ da empresa' : 'Seu CPF',
      },
      name: {
        label: values.isCompanyDocument
          ? 'Nome da sua empresa'
          : 'Nome completo',
        placeholder: values.isCompanyDocument
          ? 'Digite o nome da sua empresa'
          : 'Digite o seu nome',
      },
    };
  }, [values.isCompanyDocument]);

  return (
    <FormikProvider value={formik}>
      <span className="cartForm__formTitle">Digite seus dados</span>
      <div className="cartForm">
        <div className="cartForm__card-fields">
          <CartField
            inputId="name"
            label={infoType.name.label}
            value={values.name}
            onChange={onFieldChange('name')}
            onBlur={handleBlur}
            placeholder={infoType.name.placeholder}
            error={touched.name && errors.name}
            disabled={disabled}
          />
          <div className="cartForm__toggler-container">
            <Toggler
              label="Possui CNPJ"
              secondaryLabel="Eu tenho"
              handleChange={onDocumentTypeChange}
              initialState={values.isCompanyDocument}
            />
          </div>
          <CartField
            inputId="document"
            label={infoType.document.label}
            value={values.document}
            onChange={onFieldChange('document')}
            onBlur={handleBlur}
            placeholder={infoType.document.placeholder}
            error={touched.document && errors.document}
            maxLength={values.isCompanyDocument ? 18 : 14}
            disabled={disabled}
            mask={
              values.isCompanyDocument ? '99.999.999/9999-99' : '999.999.999-99'
            }
          />
        </div>
        <div className="cartForm__card-fields">
          <div className="cartForm__wpp-field">
            <CartField
              inputId="phone"
              label="Whatsapp"
              value={values.phone}
              onChange={onFieldChange('phone')}
              onBlur={handleBlur}
              placeholder="Whatsapp com DDD"
              mask="(99) 99999-9999"
              error={touched.phone && errors.phone}
              disabled={disabled}
            />
            <div
              className="cartForm__wpp-field__contact-disclaimer"
              role="presentation"
            >
              <img src={infoPurple} alt="info icon" />
              <p className="cartForm__wpp-field__contact-disclaimer__text">
                Entraremos em contato com você através desse número
              </p>
            </div>
          </div>
          <CartField
            inputId="email"
            label="Email"
            value={values.email}
            onChange={onFieldChange('email')}
            onBlur={handleBlur}
            placeholder="Email"
            error={touched.email && errors.email}
            disabled={disabled}
          />
        </div>
        <div className="cartForm__checkboxes">
          <CartCheckbox
            inputId="acceptCheck"
            label="Aceito receber comunicações da Eye"
            checked={values.marketingOptin}
            onChange={handleMarketingOptinChange}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="cartForm__buttonsContainer">
        <CartButton outlined disabled={isSubmitting} onClick={onBack(values)}>
          ANTERIOR
        </CartButton>
        <CartButton
          onClick={handleSubmit}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          large
        >
          PRÓXIMO
        </CartButton>
      </div>
      <HasGetnetSubscriptionModal
        options={getnetPlan}
        setOptions={handleSetGetnetPlan}
        submit={submitForm}
      />
    </FormikProvider>
  );
};

CartPersonalDataForm.defaultProps = {
  disabled: false,
};

CartPersonalDataForm.propTypes = {
  initialData: PropTypes.object.isRequired,
  isPlanWithFiscal: PropTypes.bool.isRequired,
  handleToast: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  getnetPlan: PropTypes.shape({
    hasPlanModal: PropTypes.bool.isRequired,
    acceptNewPlan: PropTypes.bool.isRequired,
  }).isRequired,
  handleSetGetnetPlan: PropTypes.func.isRequired,
};

export default CartPersonalDataForm;
