import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import './Select.styles.scss';

const Select = ({ options, selected, onChange, disabled }) => {
  const renderOptions = useMemo(() => {
    return options.map((option) => {
      const { id, value, label } = option;
      return (
        <option key={id} value={value} className="selectComponent__option">
          {label}
        </option>
      );
    });
  }, [options]);

  return (
    <div className="selectComponent">
      <select onChange={onChange} value={selected} disabled={disabled}>
        {renderOptions}
      </select>
    </div>
  );
};

Select.defaultProps = {
  disabled: false,
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Select;
