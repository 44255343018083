import React, { useContext, useMemo } from 'react';

import PropTypes from 'prop-types';

import { CartContext } from '../../contexts/cartContext';

import './CartStepper.styles.scss';

const CartStepper = ({ currentStep }) => {
  const { data } = useContext(CartContext);

  const steps = [
    {
      id: 1,
      label: 'Seu plano',
    },
    {
      id: data.planId === 'P003' || data.planId === 'P004' ? 2 : 3,
      label: 'Maquininha',
    },
    {
      id: data.planId === 'P003' || data.planId === 'P004' ? 3 : 2,
      label: 'Dados',
    },
    {
      id: 4,
      label: 'Endereço do negócio',
    },
    {
      id: 5,
      label: 'Pagamento',
    },
  ];

  const stepsSorted = steps.sort((a, b) => a.id - b.id);

  const stepsRender = useMemo(
    () =>
      stepsSorted.map(({ id, label }) => {
        if (currentStep === id) {
          return (
            <div key={id} className="cardSteps__step cardSteps__step--current">
              {label}
            </div>
          );
        }

        if (id < currentStep) {
          return (
            <div key={id} className="cardSteps__step cardSteps__step--checked">
              <img
                src="https://assets.eye.com.br/step-check.svg"
                alt="step-check"
              />
              {label}
            </div>
          );
        }

        return (
          <div key={id} className="cardSteps__step">
            {label}
          </div>
        );
      }),
    [currentStep, stepsSorted],
  );

  return <div className="cardSteps">{stepsRender}</div>;
};

CartStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default CartStepper;
