export const normalizeNumber = (value) => value?.replace(/[^\d]+/g, '');

export const normalizePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return;
  }

  const digits = `${phoneNumber}`.replace(/\D/g, '');
  const match = digits.match(/^(\d{2}|\d{0})(\d{2})(\d{5}|\d{4})(\d{4})$/);

  return match ? `(${match[2]}) ${match[3]}-${match[4]}` : undefined;
};

export const normalizeZipCode = (zipCode) => {
  if (!zipCode) {
    return;
  }

  const digits = `${zipCode}`.replace(/\D/g, '');
  const match = digits.match(/^(\d{5})(\d{3})$/);
  return match ? `${match[1]}-${match[2]}` : undefined;
};

export const normalizeDocument = (document) => {
  if (document === undefined || document === null) {
    return;
  }

  const digits = `${document}`.replace(/\D/g, '');

  if (digits.length === 14) {
    const match = digits.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
    return match
      ? `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`
      : digits;
  }

  if (digits.length === 11) {
    const match = digits.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
    return match ? `${match[1]}.${match[2]}.${match[3]}-${match[4]}` : digits;
  }

  return document;
};

export const normalizeCurrency = (value) => {
  if (value === undefined || value === null) {
    return;
  }

  return String(Number(value).toFixed(2))
    .split(',')
    .join('')
    .split('.')
    .join(',');
};
