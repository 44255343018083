import './Toggler.styles.scss';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

const Toggler = ({
  initialState,
  handleChange,
  label,
  secondaryLabel,
  disabled,
}) => {
  const [active, setActive] = useState(initialState);

  const handleToggler = (event) => {
    event.preventDefault();

    if (handleChange()) {
      setActive((prev) => !prev);
    }
  };

  return (
    <div className="toggler-wrapper">
      <span className="toggler-label">{label}</span>
      <button
        type="button"
        className={`toggler ${active ? 'active' : ''}`}
        onClick={handleToggler}
        disabled={disabled}
      >
        <div className="toggler__changer" />
      </button>
      <span className="toggler-secondary-label">{secondaryLabel}</span>
    </div>
  );
};

Toggler.defaultProps = {
  initialState: false,
  label: '',
  secondaryLabel: '',
  disabled: false,
};
Toggler.propTypes = {
  handleChange: PropTypes.func.isRequired,
  initialState: PropTypes.bool,
  label: PropTypes.string,
  secondaryLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Toggler;
