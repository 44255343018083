import React, {
  useEffect,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { ToastContainer } from 'react-toastify';

import { navigate } from 'gatsby';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'qs';

import CartButton from '../components/CartButton';
import CartIcon from '../components/CartIcon/CartIcon';
import CartOutro from '../components/CartOutro/CartOutro';
import CartPlan from '../components/CartPlan';
import CartPOS from '../components/CartPOS/CartPOS';
import CartSelectPOS from '../components/CartSelectPOS';
import CartStepper from '../components/CartStepper/CartStepper';
import Footer from '../components/Footer';
import CartAddressForm from '../components/Forms/CartAddressForm';
import CartPersonalDataForm from '../components/Forms/CartPersonalDataForm';
import PaymentForm from '../components/Forms/PaymentForm';
import { pos } from '../components/SelectPOS/SelectPOS';
import Seo from '../components/Seo';
import WhatsappButton from '../components/Whatsapp';
import { plans } from '../constants/plans';
import CartProvider, { CartContext } from '../contexts/cartContext';
import http from '../utils/axios';
import { LeadValidationError } from '../utils/errors';
import { getCookie } from '../utils/getCookie';
import { isBrowser } from '../utils/isBrowser';
import { normalizeNumber } from '../utils/normalizers';
import {
  isOriginCombinedOffer,
  isOriginGetnetLp,
} from '../utils/originGetnetLP';
import { syncDataLayer } from '../utils/syncDataLayer';
import { errorToast, infoToast } from '../utils/toast';
import tokenizeCc from '../utils/tokenizeCc';
import '../styles/cart.scss';

const CURRENT_ENV = process.env.GATSBY_ENV || 'staging';
const eyeLpStagingUrl = 'https://staging.eye.com.br';
const eyeLpProdUrl = 'https://eye.com.br';

const images = [
  '',
  'https://assets.eye.com.br/cart-side-image-desktop.png',
  'https://assets.eye.com.br/cart-side-image-desktop.png',
  'https://assets.eye.com.br/cart-side-image-desktop.png',
  'https://assets.eye.com.br/cart-side-image-desktop.png',
  'https://assets.eye.com.br/cart-side-image-thank-you-desktop.png',
];

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div className="spinner">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="7" strokeWidth="4" />
        </svg>
      </div>
    </div>
  );
};

const CartTitle = () => (
  <div className="c-page__content__main-title">
    <CartIcon src="https://assets.eye.com.br/cart.svg" alt="cart" />
    <span>Meu carrinho</span>
  </div>
);

const buildCartLink = ({
  plano,
  origin,
  utmSource,
  utmContent,
  utmMedium,
  utmCampaign,
  gClid,
  fbClid,
}) => {
  const queryString = qs.stringify({
    plano,
    origin: origin || undefined,
    utm_source: utmSource || undefined,
    utm_content: utmContent || undefined,
    utm_medium: utmMedium || undefined,
    utm_campaign: utmCampaign || undefined,
    gclid: gClid || undefined,
    fbclid: fbClid || undefined,
  });

  return queryString ? `/carrinho/?${queryString}` : '';
};

const Cart = () => {
  const { step, data, patchCart, patchData, isPlanWith } =
    useContext(CartContext);

  const {
    disabledPlanChanges,
    disabledLeadChanges,
    fromLeadUuid,
    planId,
    origin,
    utmSource,
    utmMedium,
    utmContent,
    utmCampaign,
    gClid,
    fbClid,
    planPrice,
    planQuantity,
    planTotal,
    pdvPrice,
    fiscalPrice,
    posPrice,
  } = data;

  const [showPaymentProcessingOverlay, setShowPaymentProcessingOverlay] =
    useState(false);

  const [getnetPlan, setGetnetPlan] = useState({
    hasPlanModal: false,
    acceptNewPlan: false,
  });

  const userID = getCookie('eyeUserID');

  const options = useMemo(
    () => [
      {
        id: plans.P001,
        name: 'Sistema PDV',
        subtitle: 'Sistema PDV móvel + Painel de gestão',
        value: pdvPrice,
        items: [
          {
            code: plans.P001,
            quantity: planQuantity,
            price: pdvPrice,
          },
        ],
      },
      {
        id: plans.P002,
        name: 'Sistema PDV + Fiscal',
        subtitle: 'Tudo do Sistema PDV + Emissão de Nota Fiscal',
        value: pdvPrice + (fiscalPrice || 49.9),
        items: [
          {
            code: plans.P001,
            quantity: planQuantity,
            price: pdvPrice,
          },
          {
            code: plans.P002,
            quantity: planQuantity,
            price: fiscalPrice,
          },
        ],
      },
      {
        id: plans.P003,
        name: 'Sistema PDV + Aluguel de Maquininha',
        baseName: 'Sistema PDV',
        subtitle: 'Tudo do Sistema PDV + POS Digital Getnet',
        value: pdvPrice,
        addon: {
          name: 'Maquininha Digital Getnet',
          productName: 'Aluguel de Maquininha Getnet',
          quantifier: 'máquinas',
          tooltip:
            'Você pode assinar um usuário para cada vendedor ou maquininha que tiver.',
          value: posPrice,
        },
        items: [
          {
            code: plans.P001,
            quantity: planQuantity,
            price: pdvPrice,
          },
          {
            code: plans.P003,
            quantity: planQuantity,
            price: posPrice,
          },
        ],
      },
      {
        id: plans.P004,
        name: 'Sistema PDV + Fiscal + Aluguel de Maquininha',
        baseName: 'Sistema PDV + Fiscal',
        subtitle: 'Tudo do Sistema PDV + Emissão de nota Fiscal',
        value: pdvPrice + fiscalPrice,
        addon: {
          name: 'Maquininha Digital Getnet',
          productName: 'Aluguel de Maquininha Getnet',
          quantifier: 'máquinas',
          tooltip:
            'Você pode assinar um usuário para cada vendedor ou maquininha que tiver.',
          value: posPrice,
        },
        items: [
          {
            code: plans.P001,
            quantity: planQuantity,
            price: pdvPrice,
          },
          {
            code: plans.P002,
            quantity: planQuantity,
            price: fiscalPrice,
          },
          {
            code: plans.P003,
            quantity: planQuantity,
            price: posPrice,
          },
        ],
      },
    ],
    [pdvPrice, fiscalPrice, posPrice, planQuantity],
  );

  const currentPlan = options.find((plan) =>
    planId ? plan.id === planId : plan.id === plans.default,
  );

  const setPlan = useCallback(
    (newId, planData = {}) => {
      const selectedPlan = options.find(({ id }) => id === newId);

      patchData({
        planId: selectedPlan.id,
        planPrice: selectedPlan.value,
        planQuantity: 1,
        ...planData,
      });

      return selectedPlan;
    },
    [options, patchData],
  );

  const handleSetGetnetPlan = (option) => {
    setGetnetPlan((prev) => {
      return {
        ...prev,
        ...option,
      };
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(data.qrCodeText);

    infoToast('Código PIX copiado', {
      autoClose: true,
    });
  };

  const handleIncrease = () => {
    if (disabledPlanChanges) {
      return;
    }

    if (planQuantity < 99) {
      const newQuantity = planQuantity + 1;
      patchData({
        planQuantity: newQuantity,
      });
    }
  };

  const handleDecrease = () => {
    if (disabledPlanChanges) {
      return;
    }

    if (planQuantity >= 2) {
      const newQuantity = planQuantity - 1;

      patchData({
        planQuantity: newQuantity,
      });
    }
  };

  const handleChange = (e) => {
    if (disabledPlanChanges) {
      return;
    }

    let newQuantity = Number(e.target.value.replace(/[^\d]/g, ''));

    if (newQuantity > 99) {
      newQuantity = 99;
    }

    if (newQuantity <= 0) {
      newQuantity = 1;
    }

    patchData({
      planQuantity: newQuantity,
    });
  };

  const handlePlanChange = () => {
    let defineSelectedPlan;

    switch (planId) {
      case plans.P001:
        defineSelectedPlan = plans.P002;
        break;
      case plans.P002:
        defineSelectedPlan = plans.P001;
        break;
      case plans.P003:
        defineSelectedPlan = plans.P004;
        break;
      default:
        defineSelectedPlan = plans.P003;
    }

    const selectedPlan = setPlan(defineSelectedPlan, {
      planQuantity,
    });

    if (!fromLeadUuid) {
      navigate(
        buildCartLink({
          plano: selectedPlan.id,
          origin,
          utmSource,
          utmContent,
          utmMedium,
          utmCampaign,
          gClid,
          fbClid,
        }),
        { replace: true },
      );
    }

    return true;
  };

  const handlePosChange = () => {
    let defineSelectedPlan;

    switch (planId) {
      case plans.P001:
        defineSelectedPlan = plans.P003;
        break;
      case plans.P003:
        defineSelectedPlan = plans.P001;
        break;
      case plans.P002:
        defineSelectedPlan = plans.P004;
        break;
      case plans.P004:
        defineSelectedPlan = plans.P002;
        break;
      default:
        defineSelectedPlan = plans.P003;
    }

    const selectedPlan = setPlan(defineSelectedPlan, {
      planQuantity,
    });

    if (!fromLeadUuid) {
      navigate(
        buildCartLink({
          plano: selectedPlan.id,
          origin,
          utmSource,
          utmContent,
          utmMedium,
          utmCampaign,
          gClid,
          fbClid,
        }),
        { replace: true },
      );
    }

    return true;
  };

  const handleSavePOSOptions = async (values) => {
    try {
      if (!values.terms) {
        throw new Error(
          'Para prosseguir é necessário aceitar os termos e condições',
        );
      }

      patchCart({
        step: 3,
        data: {
          anticipationMethod: values.anticipationMethod,
          terms: values.terms,
          parcelsQnt: values.parcelsQnt,
        },
      });
    } catch (err) {
      errorToast(err.message, {
        toastId: 'eye-terms-error',
      });
    }
  };

  const handleSaveCurrentDevice = async (values) => {
    try {
      if (!disabledLeadChanges && !values.posModel) {
        throw new LeadValidationError(
          'Para continuar confira se a sua maquininha é suportada e selecione o fabricante e o modelo dela',
        );
      }

      patchCart({
        step: 4,
        data: values,
      });
    } catch (err) {
      patchData(values);

      if (err instanceof LeadValidationError) {
        errorToast(err.message);
      }
    }
  };

  const checkHasAccreditation = async (document) => {
    const normalizeDocument = normalizeNumber(document);

    try {
      const {
        data: { isAccredited },
      } = await http.get(
        `/acquirer/account/is-accredited/${normalizeDocument}`,
      );
      return isAccredited;
    } catch (err) {
      console.error(err);
    }
  };

  const handlePreRegisterLead = async (values, formikBag) => {
    formikBag.setSubmitting(true);
    const { document } = values;

    const hasGetnetPlan = await checkHasAccreditation(document);

    if (hasGetnetPlan && !getnetPlan.acceptNewPlan) {
      handleSetGetnetPlan({ hasPlanModal: true });
      formikBag.setSubmitting(false);
      return;
    }

    try {
      if (normalizeNumber(document).length === 11 && isPlanWith.fiscal) {
        throw new LeadValidationError(
          'Para emitir NFC-e você precisa de um CNPJ e não deve conseguir seguir com o cadastro com CPF.  ',
        );
      }

      const phone = normalizeNumber(values.phone);

      const items = options.find((option) => option.id === planId)?.items;

      if (!data.uuid) {
        const lead = {
          name: values.name,
          email: values.email,
          phone,
          document: normalizeNumber(values.document),
          origin: 'LANDING_PAGE_EYE',
          ...(isOriginGetnetLp(origin) && {
            originUrl: `/lp/oferta-sistema-getnet/?vers=${origin}`,
          }),
          items,
          utmContent: utmContent || null,
          utmMedium: utmMedium || null,
          utmSource: utmSource || null,
          utmCampaign: utmCampaign || null,
          gClid: gClid || null,
          fbClid: fbClid || null,
          marketingOptin: values.marketingOptin,
          uuid: userID,
        };

        const response = await http.post(
          '/subscriptions/lead/preregister',
          lead,
        );

        syncDataLayer({
          email: values.email,
          phone,
          items,
        });

        if (isBrowser) {
          window.dataLayer.push({
            event: 'create-lead',
          });
        }

        patchCart({
          step: isPlanWith.POS ? 4 : 3,
          data: {
            ...values,
            uuid: response.data.uuid,
          },
        });
      } else {
        patchCart({
          step: isPlanWith.POS ? 4 : 3,
          data: {
            ...values,
            existentLead: true,
          },
        });
      }
    } catch (err) {
      const message = err?.response?.data?.message;

      patchData(values);

      if (err instanceof LeadValidationError) {
        errorToast(err.message);
      }

      if (message) {
        if (message.includes('Email já cadastrado')) {
          errorToast(
            'Email já cadastrado no sistema, entre em contato com o nosso suporte.',
          );
        } else if (message.includes('Telefone já cadastrado')) {
          errorToast(
            'Telefone já cadastrado no sistema, entre em contato com o nosso suporte.',
          );
        } else if (message.includes('Documento já cadastrado')) {
          errorToast(
            'CPF ou CNPJ já cadastrado no sistema, entre em contato com o nosso suporte.',
          );
        } else {
          errorToast(message);
        }
      }

      formikBag.setSubmitting(false);
    }
  };

  const handlePreRegisterUpdate = async (values, formikBag) => {
    try {
      formikBag.setSubmitting(true);

      if (!data.uuid) {
        throw new LeadValidationError(
          'Não foi possível continuar com o seu cadastro, recarregue a página e tente novamente.',
        );
      }

      const phone = normalizeNumber(data.phone);

      const posModel = pos.find((model) => model.id === data.posModel)?.name;

      const items = options.find((option) => option.id === planId)?.items;

      if (!disabledLeadChanges) {
        const lead = {
          name: data.name,
          email: data.email,
          phone,
          document: normalizeNumber(data.document),
          address: values.address,
          address2: values.addressComplement,
          addressNumber: values.addressNumber,
          district: values.district,
          city: values.city,
          zipcode: normalizeNumber(values.zipCode),
          state: values.state,
          items,
          ...((isPlanWith.POS || (!isPlanWith.POS && posModel)) && {
            metadata: {
              ...(isPlanWith.POS
                ? {
                    anticipationMethod: data.anticipationMethod,
                    parcelsQnt: data.parcelsQnt,
                  }
                : {}),
              ...(!isPlanWith.POS
                ? {
                    posModel,
                    posGroup: data.posGroup,
                  }
                : {}),
            },
          }),
        };

        const response = await http.put(
          `/subscriptions/lead/preregister/${data.uuid}`,
          lead,
        );

        patchCart({
          step: 5,
          data: {
            ...values,
            customerId: response.data.customerId,
          },
        });

        syncDataLayer(
          {
            email: data.email,
            phone,
            items,
          },
          origin,
        );
      } else {
        patchCart({
          step: 5,
          data: {
            ...values,
          },
        });
      }

      formikBag.setSubmitting(false);
    } catch (err) {
      const message = err?.response?.data?.message;

      patchData(values);

      if (err instanceof LeadValidationError) {
        errorToast(err.message);
      } else if (message) {
        if (message.includes('Email já cadastrado')) {
          errorToast(
            'Email já cadastrado no sistema, entre em contato com o nosso suporte.',
          );
        } else if (message.includes('Telefone já cadastrado')) {
          errorToast(
            'Telefone já cadastrado no sistema, entre em contato com o nosso suporte.',
          );
        } else if (message.includes('Documento já cadastrado')) {
          errorToast(
            'CPF ou CNPJ já cadastrado no sistema, entre em contato com o nosso suporte.',
          );
        } else {
          errorToast(message);
        }
      } else {
        errorToast(message);
      }

      formikBag.setSubmitting(false);
    }
  };

  const handlePaymentSubmit = async (values, formikBag) => {
    try {
      formikBag.setSubmitting(true);
      setShowPaymentProcessingOverlay(true);

      const tokenizedCC = await tokenizeCc(values);
      const tokenizedRecurrentCC = await tokenizeCc(values);

      const response = await http
        .post(`/subscriptions/lead/${data.uuid}/subscription`, {
          method: 'credit-card',
          token: tokenizedCC.id,
          recurrentToken: tokenizedRecurrentCC.id,
        })
        .then(({ data: responseData }) => responseData);

      patchData({ subscriptionId: response.subscriptionId });
    } catch (err) {
      errorToast(
        'Falha ao processar o pagamento. Por favor, confira os dados e tente novamente ou tente com outro método de pagamento.',
      );
      setShowPaymentProcessingOverlay(false);
      formikBag.setSubmitting(false);
    }
  };

  if (step === 6) {
    return <CartOutro />;
  }

  if (step === 5) {
    return (
      <>
        <CartTitle />
        <CartStepper currentStep={step} />
        <PaymentForm
          initialData={data}
          onFormSubmit={handlePaymentSubmit}
          onBack={(values) => patchCart({ step: 4, data: values || {} })}
          onCopy={handleCopy}
          showOverlay={showPaymentProcessingOverlay}
          disabled={disabledLeadChanges}
          plan={currentPlan}
        />
      </>
    );
  }

  if (step === 4) {
    return (
      <>
        <span className="cartForm__formTitle">Meu carrinho</span>
        <CartStepper currentStep={step} />
        <CartAddressForm
          initialData={data}
          onFormSubmit={handlePreRegisterUpdate}
          onBack={(values) => patchCart({ step: 3, data: values || {} })}
          disabled={disabledLeadChanges}
        />
      </>
    );
  }

  if (step === 3) {
    const handleBack = (values) => () => patchCart({ step: 2, data: values });

    const handlePosPlan = () =>
      patchCart({
        step: 1,
        data: {
          planId: plans.P003,
          planPrice: 99.9,
          posPrice: 39.9,
        },
      });
    const renderStepByPlan = isPlanWith.POS ? (
      <CartPersonalDataForm
        initialData={data}
        isPlanWithFiscal={isPlanWith.fiscal}
        handleToast={errorToast}
        onFormSubmit={handlePreRegisterLead}
        onBack={handleBack}
        disabled={disabledLeadChanges}
        getnetPlan={getnetPlan}
        handleSetGetnetPlan={handleSetGetnetPlan}
      />
    ) : (
      <>
        <div className="c-page__content__titleWrapper">
          <span className="c-page__content__titleWrapper__title">
            Sua maquininha
          </span>
        </div>
        <CartSelectPOS
          onBack={handleBack}
          handlePosPlan={handlePosPlan}
          posGroup={data?.posGroup}
          posModel={data?.posModel}
          onFormSubmit={handleSaveCurrentDevice}
          disabled={disabledLeadChanges}
        />
      </>
    );

    return (
      <>
        <CartTitle />
        <CartStepper currentStep={step} />
        {renderStepByPlan}
      </>
    );
  }

  if (step === 2) {
    const handleBack = (values) => () => patchCart({ step: 1, data: values });

    const renderStepByPlan = isPlanWith.POS ? (
      <>
        <div className="c-page__content__titleWrapper">
          <span className="c-page__content__titleWrapper__title">
            Sua maquininha
          </span>
        </div>
        <CartPOS
          onBack={handleBack}
          onFormSubmit={handleSavePOSOptions}
          disabled={disabledLeadChanges}
          anticipationMethod={data.anticipationMethod}
          parcelsQnt={data.parcelsQnt}
          terms={data.terms}
        />
      </>
    ) : (
      <CartPersonalDataForm
        initialData={data}
        isPlanWithFiscal={isPlanWith.fiscal}
        handleToast={errorToast}
        onFormSubmit={handlePreRegisterLead}
        onBack={handleBack}
        disabled={disabledLeadChanges}
        getnetPlan={getnetPlan}
        handleSetGetnetPlan={handleSetGetnetPlan}
      />
    );

    return (
      <>
        <CartTitle />
        <CartStepper currentStep={step} />
        {renderStepByPlan}
      </>
    );
  }

  return (
    <>
      <CartTitle />
      <CartPlan
        planId={planId}
        plan={currentPlan}
        planPrice={planPrice}
        planQuantity={planQuantity}
        planChange={handlePlanChange}
        fiscalPrice={fiscalPrice}
        handlePosChange={handlePosChange}
        total={planTotal}
        posPrice={posPrice}
        disabled={disabledPlanChanges}
        onQuantityIncrease={handleIncrease}
        onQuantityDecrease={handleDecrease}
        onQuantityChange={handleChange}
        lpOrigin={origin}
      />
      <div className="c-page__content__buttonsContainer c-page__content__buttonsContainer--single-button">
        <CartButton
          disabled={planQuantity <= 0}
          onClick={() => {
            patchCart({ step: 2, data: {} });
          }}
          large
        >
          PRÓXIMO
        </CartButton>
      </div>
    </>
  );
};

const CartContainer = () => {
  const [hasFetched, setHasFetched] = useState(false);
  const {
    step,
    data: {
      leadUuid,
      planId,
      origin,
      utmSource,
      utmMedium,
      utmContent,
      utmCampaign,
      gClid,
      fbClid,
    },
    isPlanWith,
    patchCart,
  } = useContext(CartContext);

  const eyeUserId = getCookie('eyeUserID');

  const buildRootLink = () => {
    const queryString = qs.stringify({
      utm_source: utmSource || undefined,
      utm_content: utmContent || undefined,
      utm_medium: utmMedium || undefined,
      utm_campaign: utmCampaign || undefined,
      gclid: gClid || undefined,
      fbclid: fbClid || undefined,
    });

    return queryString ? `?${queryString}` : '';
  };

  const handleGoBack = () => navigate(`/${buildRootLink()}`);

  const goBackToLp = () => {
    const LpToRedirect =
      CURRENT_ENV === 'staging' ? eyeLpStagingUrl : eyeLpProdUrl;

    if (isOriginGetnetLp(origin)) {
      window.location.href = `${LpToRedirect}/lp/oferta-sistema-getnet/?vers=${origin}`;
      return;
    }
    if (isOriginCombinedOffer(origin)) {
      window.location.href = `${LpToRedirect}/lp/oferta-combinada/`;
      return;
    }
    handleGoBack();
  };

  const renderWhatsappButton = useMemo(() => {
    if ((isPlanWith.POS && step < 3) || (!isPlanWith.POS && step < 2)) {
      return null;
    }
    return <WhatsappButton />;
  }, [step, isPlanWith.POS]);

  const fetchLead = useCallback(async () => {
    const uuid = leadUuid || eyeUserId;

    if (uuid) {
      try {
        const response = await http
          .get('/subscriptions/lead', {
            params: { uuid },
          })
          .then(({ data: responseData }) => responseData);

        if (response) {
          const {
            name,
            email,
            phone,
            document,
            address,
            address2: addressComplement,
            addressNumber,
            district,
            city,
            state,
            zipcode: zipCode,
            subscriptionId,
            conversionDate,
            items,
          } = response;

          const pdvItem = items.find((item) => item.code === plans.P001);
          const fiscalItem = items.find((item) => item.code === plans.P002);
          const posItem = items.find((item) => item.code === plans.P003);

          const currentPlanId =
            pdvItem?.quantity && fiscalItem?.quantity && posItem?.quantity
              ? plans.P004
              : (posItem?.quantity && posItem?.code) ||
                (fiscalItem?.quantity && fiscalItem?.code) ||
                (pdvItem?.quantity && pdvItem?.code);

          const planPrice = items.reduce((acc, curr) => {
            if ([plans.P001, plans.P002].includes(currentPlanId)) {
              return acc + Number(curr.price, 10);
            }

            return curr.code === plans.P003 ? acc : acc + Number(curr.price);
          }, 0);

          const planQuantity = pdvItem?.quantity || 1;

          const pdvPrice = Number(pdvItem?.price) || 99.9;
          const fiscalPrice = Number(fiscalItem?.price) || 49.9;
          const posPrice = Number(posItem?.price, 10) || 49.9;

          const disabledPlanChanges = !!subscriptionId;
          const disabledLeadChanges = !!conversionDate;

          patchCart({
            step: leadUuid ? (posItem && 5) || 5 : 1,
            data: {
              uuid,
              disabledPlanChanges,
              disabledLeadChanges,
              ...((leadUuid || disabledPlanChanges) && {
                fromLeadUuid: !!leadUuid,
                planId: currentPlanId,
                planPrice,
                planQuantity,
                pdvPrice,
                fiscalPrice,
                posPrice,
                items,
              }),
              name,
              email,
              phone,
              document,
              zipCode,
              address,
              addressNumber,
              addressComplement,
              city,
              district,
              state,
            },
          });
        } else if (leadUuid) {
          throw new LeadValidationError(
            'Proposta não encontrada. Verifique se o link está completo e tente novamente.',
          );
        }
      } catch (err) {
        let errorMessage =
          'Não foi possível acessar a proposta. Recarregue a página e tente novamente.';

        if (err instanceof LeadValidationError) {
          errorMessage = err.message;
        }

        errorToast(errorMessage);
      }
    }

    setHasFetched(true);
  }, [leadUuid, eyeUserId, patchCart]);

  useEffect(() => {
    if (!leadUuid && !plans[planId]) {
      navigate(
        buildCartLink({
          plano: plans.default,
          origin,
          utmSource,
          utmContent,
          utmMedium,
          utmCampaign,
          gClid,
          fbClid,
        }),
        { replace: true },
      );
    }

    if (!hasFetched) {
      fetchLead();
    }
  }, [
    hasFetched,
    planId,
    leadUuid,
    origin,
    utmSource,
    utmContent,
    utmMedium,
    utmCampaign,
    gClid,
    fbClid,
    patchCart,
    fetchLead,
  ]);

  return (
    <>
      <Seo title="Carrinho" />
      <div className="c-page">
        <div className="c-page__header">
          <div className="c-page__header__container">
            <div className="c-page__header__container__innerWrapper">
              <div className="c-page__header__container__innerWrapper__corner">
                {hasFetched && !!leadUuid && (
                  <button
                    onClick={goBackToLp}
                    disabled={!hasFetched}
                    type="button"
                    className="c-page__back-button is-mobile"
                  >
                    <img
                      src="https://assets.eye.com.br/mobile-cart-back-arrow.svg"
                      alt="back-arrow"
                      className="c-page__back-button__arrow"
                    />
                    Voltar
                  </button>
                )}
              </div>
              <img
                className="c-page__header__container__innerWrapper__logo"
                src="https://assets.eye.com.br/logo.svg"
                alt="logo"
              />
              <div className="c-page__header__container__innerWrapper__corner" />
            </div>
          </div>
        </div>
        <div className="c-page__wrapper">
          <div className="c-page__image is-desktop">
            <img
              src={images[step]}
              alt="Imagem lateral do carrinho"
              width="894px"
              height="800px"
            />
          </div>
          <div className="c-page__content">
            {hasFetched && !leadUuid && (
              <button
                onClick={goBackToLp}
                disabled={!hasFetched}
                type="button"
                className="c-page__back-button c-page__back-button--content is-desktop"
              >
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="c-page__back-button__arrow"
                >
                  <path
                    d="M7.03611 10.7181L2.41611 6.09805C2.31389 5.99525 2.19235 5.91367 2.05849 5.85801C1.92463 5.80234 1.78108 5.77368 1.63611 5.77368C1.49114 5.77368 1.34759 5.80234 1.21373 5.85801C1.07987 5.91367 0.958333 5.99525 0.856109 6.09805C0.75331 6.20028 0.671731 6.32181 0.616063 6.45567C0.560396 6.58953 0.531738 6.73308 0.531738 6.87805C0.531738 7.02303 0.560396 7.16657 0.616063 7.30043C0.671731 7.43429 0.75331 7.55583 0.856109 7.65805L5.47611 12.2781C5.57833 12.3809 5.69987 12.4624 5.83373 12.5181C5.96759 12.5738 6.11114 12.6024 6.25611 12.6024C6.40108 12.6024 6.54463 12.5738 6.67849 12.5181C6.81235 12.4624 6.93389 12.3809 7.03611 12.2781C7.13891 12.1758 7.22049 12.0543 7.27616 11.9204C7.33182 11.7866 7.36048 11.643 7.36048 11.4981C7.36048 11.3531 7.33182 11.2095 7.27616 11.0757C7.22049 10.9418 7.13891 10.8203 7.03611 10.7181Z"
                    fill="#E7B2FF"
                  />
                  <path
                    d="M5.48002 1.19803L0.860016 5.82203C0.757216 5.92425 0.675637 6.04579 0.619969 6.17965C0.564302 6.31351 0.535645 6.45705 0.535645 6.60203C0.535645 6.747 0.564302 6.89055 0.619969 7.02441C0.675637 7.15827 0.757216 7.2798 0.860016 7.38203C0.96224 7.48483 1.08378 7.56641 1.21764 7.62207C1.3515 7.67774 1.49504 7.7064 1.64002 7.7064C1.78499 7.7064 1.92853 7.67774 2.06239 7.62207C2.19626 7.56641 2.31779 7.48483 2.42002 7.38203L7.03602 2.75803C7.13882 2.6558 7.2204 2.53427 7.27606 2.40041C7.33173 2.26655 7.36039 2.123 7.36039 1.97803C7.36039 1.83305 7.33173 1.68951 7.27606 1.55565C7.2204 1.42179 7.13882 1.30025 7.03602 1.19803C6.93379 1.09523 6.81226 1.01365 6.6784 0.957982C6.54453 0.902315 6.40099 0.873657 6.25602 0.873657C6.11104 0.873657 5.9675 0.902315 5.83364 0.957982C5.69978 1.01365 5.57824 1.09523 5.47602 1.19803H5.48002Z"
                    fill="#E7B2FF"
                  />
                </svg>
                Voltar
              </button>
            )}
            {(hasFetched && <Cart />) || <Loading />}
          </div>
        </div>
        <Footer variant />
        {renderWhatsappButton}
      </div>
    </>
  );
};

const CartWrapper = () => (
  <CartProvider>
    <ToastContainer className="cartErrorToast__container" />
    <CartContainer />
  </CartProvider>
);

CartWrapper.propTypes = {};

export default CartWrapper;
