export const isOriginGetnetLp = (origin) => {
  if (origin) {
    return ['a-taxa', 'b-prod', 'c-price'].includes(origin);
  }
  return false;
};

export const isOriginCombinedOffer = (origin) => {
  if (origin) {
    return ['oferta-combinada'].includes(origin);
  }
  return false;
};
