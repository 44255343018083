import React from 'react';

import PropTypes from 'prop-types';

import warningYellow from '../../images/warning-yellow.svg';

import './style.scss';

const HasGetnetSubscriptionModal = ({ options, setOptions, submit }) => {
  const { hasPlanModal } = options;

  const handleCloseModal = () => {
    setOptions({ hasPlanModal: false });
  };

  const agreeWithNewPlan = () => {
    setOptions({ acceptNewPlan: true, hasPlanModal: false });
    submit();
  };

  if (!hasPlanModal) return null;

  return (
    <div className="hasGetnet__modal">
      <div className="hasGetnet__box">
        <header className="hasGetnet__header">
          <button
            type="button"
            className="hasGetnet__header__close"
            aria-label="close button"
            onClick={handleCloseModal}
          >
            x
          </button>
        </header>
        <div className="hasGetnet__body">
          <div className="hasGetnet__icon-container">
            <img src={warningYellow} alt="ícone warning" />
          </div>
          <div className="hasGetnet__content">
            <h2 className="hasGetnet__content__heading">
              Você já tem cadastro na Getnet!
            </h2>
            <p className="hasGetnet__content__description">
              Você pode prosseguir com a oferta de Sistema PDV + Máquina, mas
              <strong>
                {' '}
                suas taxas permanecerão as do seu contrato atual
              </strong>{' '}
              com a Getnet.
            </p>
          </div>
        </div>
        <footer className="hasGetnet__footer">
          <button
            type="button"
            className="hasGetnet__footer__go-back"
            onClick={handleCloseModal}
          >
            Voltar
          </button>
          <button
            type="button"
            className="hasGetnet__footer__go-forward"
            onClick={agreeWithNewPlan}
          >
            Prosseguir
          </button>
        </footer>
      </div>
    </div>
  );
};

HasGetnetSubscriptionModal.propTypes = {
  options: PropTypes.shape({
    hasPlanModal: PropTypes.bool.isRequired,
    acceptNewPlan: PropTypes.bool.isRequired,
  }).isRequired,
  setOptions: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default HasGetnetSubscriptionModal;
