import React from 'react';

import PropTypes from 'prop-types';

import './CartIcon.styles.scss';

const CartIcon = ({ src, alt }) => {
  return <img src={src} alt={alt} className="cartIcon" />;
};

CartIcon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default CartIcon;
