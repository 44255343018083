import { toast } from 'react-toastify';

export const errorToast = (message, options = {}) => {
  toast(message, {
    position: toast.POSITION.TOP_CENTER,
    bodyClassName: 'cartErrorToast__body',
    className: 'cartErrorToast__toast',
    hideProgressBar: true,
    ...options,
  });
};

export const infoToast = (message, options = {}) => {
  toast(message, {
    position: toast.POSITION.TOP_CENTER,
    bodyClassName: 'cartInfoToast__body',
    className: 'cartInfoToast__toast',
    hideProgressBar: true,
    ...options,
  });
};
