import './SelectPOS.styles.scss';
import * as PropTypes from 'prop-types';

export const pos = [
  {
    id: '1',
    name: 'A8 ingenico',
    group: 'getnet',
    image: 'https://assets.eye.com.br/getnet-pdv-logo.png',
  },
  {
    id: '2',
    name: 'N910',
    group: 'getnet',
    image: 'https://assets.eye.com.br/getnet-pdv-logo-2.png',
  },
  {
    id: '3',
    name: 'A930',
    group: 'pagseguro',
    image: 'https://assets.eye.com.br/pagseguro-pdv-logo.png',
  },
  {
    id: '4',
    name: 'P2',
    group: 'pagseguro',
    image: 'https://assets.eye.com.br/pagseguro-pdv-logo-2.png',
  },
  {
    id: '5',
    name: 'A8',
    group: 'stone',
    image: 'https://assets.eye.com.br/stone-pdv-logo.png',
  },
  {
    id: '6',
    name: 'P2',
    group: 'stone',
    image: 'https://assets.eye.com.br/stone-pdv-logo-2.png',
  },
  {
    id: '7',
    name: 'Lio V2',
    group: 'cielo',
    image: 'https://assets.eye.com.br/cielo-pdv-logo.png',
  },
  {
    id: '8',
    name: 'GPOS700',
    group: 'rede',
    image: 'https://assets.eye.com.br/rede-pdv-logo.png',
  },
];

const SelectPOS = ({ group, selected, onChange, disabled }) => {
  const filteredPos = pos.filter((item) => item.group === group);

  return (
    <div className="selectPos">
      <span className="selectPos__label">Selecione o modelo</span>
      <div className="selectPos__container">
        {filteredPos.map((item) => (
          <button
            onClick={onChange}
            type="button"
            className={`selectPos__item
            ${item.id === selected && 'selectPos__selected'}`}
            value={item.id}
            disabled={disabled}
          >
            <img src={item.image} alt={item.name} width="67px" height="135px" />
            <span>{item.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

SelectPOS.defaultProps = {
  disabled: false,
};

SelectPOS.propTypes = {
  group: PropTypes.string.isRequired,
  selected: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectPOS;
