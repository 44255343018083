import React, { useState } from 'react';

import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import './Forms.styles.scss';

import { normalizeZipCode } from '../../utils/normalizers';
import validateState from '../../utils/validateState';
import CartButton from '../CartButton';
import CartField from '../CartField';

const validationSchema = Yup.object().shape({
  zipCode: Yup.string()
    .required('Campo obrigatório')
    .test(
      'validate-zipcode',
      'CEP inválido',
      function validateCardNumber(value) {
        return /^[0-9]{5}-[0-9]{3}$/.test(value);
      },
    ),
  address: Yup.string().required('Campo obrigatório'),
  addressNumber: Yup.string().required('Campo obrigatório'),
  addressComplement: Yup.string(),
  city: Yup.string().required('Campo obrigatório'),
  district: Yup.string().required('Campo obrigatório'),
  state: Yup.string()
    .required('Campo obrigatório')
    .test('validate-state', 'UF inválida', function validateFormState(value) {
      return validateState(value);
    }),
});

const CartAddressForm = ({
  onBack,
  values,
  errors,
  touched,
  disabled,
  setFieldValue,
  handleSubmit,
  handleBlur,
  setFieldError,
  isSubmitting,
}) => {
  const [isLoadingCep, setIsLoadingCep] = useState(false);

  const fetchCepData = async (e) => {
    if (!isLoadingCep) {
      const cep = e.target.value;
      const trimmedCep = cep.replace(/\D/g, '').trim();
      if (trimmedCep.length === 8) {
        setIsLoadingCep(true);
        await fetch(`https://viacep.com.br/ws/${trimmedCep}/json/`)
          .then((results) => results.json())
          .then((data) => {
            if (data.erro) {
              setFieldError('zipCode', 'CEP inválido');
            } else {
              setFieldValue('address', data.logradouro);
              setFieldValue('city', data.localidade);
              setFieldValue('state', data.uf);
              setFieldValue('district', data.bairro);
            }
          })
          .catch((err) => console.error(err));
        setIsLoadingCep(false);
      }
    }
  };

  const onFieldChange = (field) => (e) => setFieldValue(field, e.target.value);

  const handleBack = () => {
    onBack(values);
  };

  return (
    <div className="cartForm">
      <span className="cartForm__formTitle">Digite seu endereço</span>
      <div className="cartForm__fieldRow">
        <div className="cartForm__fieldRow__smallField">
          <CartField
            inputId="zipCode"
            label="CEP"
            value={values.zipCode}
            onChange={(e) => {
              onFieldChange('zipCode')(e);
              fetchCepData(e);
            }}
            onBlur={handleBlur}
            placeholder="CEP"
            mask="99999-999"
            error={touched.zipCode && errors.zipCode}
            disabled={disabled}
          />
        </div>
        <div className="cartForm__fieldRow__bigField">
          <CartField
            inputId="district"
            label="Bairro"
            value={values.district}
            onChange={onFieldChange('district')}
            onBlur={handleBlur}
            placeholder="Bairro"
            error={touched.district && errors.district}
            disabled={disabled}
          />
        </div>
      </div>

      <CartField
        inputId="address"
        label="Endereço"
        value={values.address}
        onChange={onFieldChange('address')}
        onBlur={handleBlur}
        placeholder="Endereço"
        error={touched.address && errors.address}
        disabled={disabled}
      />
      <div className="cartForm__fieldRow">
        <div className="cartForm__fieldRow__smallField">
          <CartField
            inputId="addressNumber"
            label="Número"
            value={values.addressNumber}
            onChange={onFieldChange('addressNumber')}
            onBlur={handleBlur}
            placeholder="Número"
            mask="999999"
            error={touched.addressNumber && errors.addressNumber}
            disabled={disabled}
          />
        </div>
        <div className="cartForm__fieldRow__bigField">
          <CartField
            inputId="addressComplement"
            label="Complemento"
            value={values.addressComplement}
            onChange={onFieldChange('addressComplement')}
            onBlur={handleBlur}
            placeholder="Complemento"
            disabled={disabled}
          />
        </div>
      </div>

      <div className="cartForm__fieldRow">
        <div className="cartForm__fieldRow__bigField">
          <CartField
            inputId="city"
            label="Cidade"
            value={values.city}
            onChange={onFieldChange('city')}
            onBlur={handleBlur}
            placeholder="Cidade"
            error={touched.city && errors.city}
            disabled={disabled}
          />
        </div>
        <div className="cartForm__fieldRow__smallField">
          <CartField
            inputId="state"
            label="UF"
            value={values.state}
            onChange={onFieldChange('state')}
            onBlur={handleBlur}
            placeholder="UF"
            mask="aa"
            error={touched.state && errors.state}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="cartForm__buttonsContainer">
        <CartButton outlined onClick={handleBack} disabled={isSubmitting}>
          ANTERIOR
        </CartButton>
        <CartButton
          onClick={handleSubmit}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          large
        >
          PRÓXIMO
        </CartButton>
      </div>
    </div>
  );
};

CartAddressForm.defaultProps = {
  disabled: false,
};

CartAddressForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default withFormik({
  mapPropsToValues: ({ initialData }) => ({
    zipCode: normalizeZipCode(initialData?.zipCode) || '',
    address: initialData?.address || '',
    addressNumber: initialData?.addressNumber || '',
    addressComplement: initialData?.addressComplement || '',
    district: initialData?.district || '',
    city: initialData?.city || '',
    state: initialData?.state || '',
  }),
  validationSchema,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onFormSubmit(values, formikBag);
  },
  enableReinitialize: true,
  validateOnMount: true,
})(CartAddressForm);
