import axios from 'axios';

const CURRENT_ENV = process.env.GATSBY_ENV || 'staging';

const hosts = {
  staging: 'https://staging-gateway.eye.com.br/api/v1',
  production: 'https://gateway.eye.com.br/api/v1',
};

const instance = axios.create({
  baseURL: hosts[CURRENT_ENV],
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 30000,
});

export default instance;
