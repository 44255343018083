import React, { useState, useEffect } from 'react';

import { navigate } from 'gatsby';
import * as PropTypes from 'prop-types';

import CartOutro from '../../components/CartOutro/CartOutro';
import Footer from '../../components/Footer';
import Seo from '../../components/Seo';

const Obrigado = ({ location }) => {
  const { state = {} } = location;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!state?.finished) {
      navigate('/carrinho');
    } else {
      setIsLoading(false);
    }
  }, [state]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Seo title="Obrigado" />
      <div className="c-page">
        <div className="c-page__header">
          <div className="c-page__header__container">
            <div className="c-page__header__container__innerWrapper">
              <div className="c-page__header__container__innerWrapper__corner">
                <button
                  onClick={() => navigate('/')}
                  type="button"
                  className="c-page__back-button is-mobile"
                >
                  <img
                    src="https://assets.eye.com.br/mobile-cart-back-arrow.svg"
                    alt="back-arrow"
                    className="c-page__back-button__arrow"
                  />
                  Voltar
                </button>
              </div>
              <img
                className="c-page__header__container__innerWrapper__logo"
                src="https://assets.eye.com.br/logo.svg"
                alt="logo"
              />
              <div className="c-page__header__container__innerWrapper__corner" />
            </div>
          </div>
        </div>
        <div className="c-page__wrapper">
          <div className="c-page__image is-desktop">
            <img
              src="https://assets.eye.com.br/cart-side-image-thank-you-desktop.png"
              alt="Imagem Obrigado"
              width="894px"
              height="800px"
            />
          </div>
          <div className="c-page__content c-page__content--variant">
            <button
              onClick={() => navigate('/')}
              type="button"
              className="c-page__back-button c-page__back-button--content is-desktop"
            >
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="c-page__back-button__arrow"
              >
                <path
                  d="M7.03611 10.7181L2.41611 6.09805C2.31389 5.99525 2.19235 5.91367 2.05849 5.85801C1.92463 5.80234 1.78108 5.77368 1.63611 5.77368C1.49114 5.77368 1.34759 5.80234 1.21373 5.85801C1.07987 5.91367 0.958333 5.99525 0.856109 6.09805C0.75331 6.20028 0.671731 6.32181 0.616063 6.45567C0.560396 6.58953 0.531738 6.73308 0.531738 6.87805C0.531738 7.02303 0.560396 7.16657 0.616063 7.30043C0.671731 7.43429 0.75331 7.55583 0.856109 7.65805L5.47611 12.2781C5.57833 12.3809 5.69987 12.4624 5.83373 12.5181C5.96759 12.5738 6.11114 12.6024 6.25611 12.6024C6.40108 12.6024 6.54463 12.5738 6.67849 12.5181C6.81235 12.4624 6.93389 12.3809 7.03611 12.2781C7.13891 12.1758 7.22049 12.0543 7.27616 11.9204C7.33182 11.7866 7.36048 11.643 7.36048 11.4981C7.36048 11.3531 7.33182 11.2095 7.27616 11.0757C7.22049 10.9418 7.13891 10.8203 7.03611 10.7181Z"
                  fill="#E7B2FF"
                />
                <path
                  d="M5.48002 1.19803L0.860016 5.82203C0.757216 5.92425 0.675637 6.04579 0.619969 6.17965C0.564302 6.31351 0.535645 6.45705 0.535645 6.60203C0.535645 6.747 0.564302 6.89055 0.619969 7.02441C0.675637 7.15827 0.757216 7.2798 0.860016 7.38203C0.96224 7.48483 1.08378 7.56641 1.21764 7.62207C1.3515 7.67774 1.49504 7.7064 1.64002 7.7064C1.78499 7.7064 1.92853 7.67774 2.06239 7.62207C2.19626 7.56641 2.31779 7.48483 2.42002 7.38203L7.03602 2.75803C7.13882 2.6558 7.2204 2.53427 7.27606 2.40041C7.33173 2.26655 7.36039 2.123 7.36039 1.97803C7.36039 1.83305 7.33173 1.68951 7.27606 1.55565C7.2204 1.42179 7.13882 1.30025 7.03602 1.19803C6.93379 1.09523 6.81226 1.01365 6.6784 0.957982C6.54453 0.902315 6.40099 0.873657 6.25602 0.873657C6.11104 0.873657 5.9675 0.902315 5.83364 0.957982C5.69978 1.01365 5.57824 1.09523 5.47602 1.19803H5.48002Z"
                  fill="#E7B2FF"
                />
              </svg>
              Voltar
            </button>
            <CartOutro />
          </div>
        </div>
        <Footer variant />
      </div>
    </>
  );
};

Obrigado.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Obrigado;
