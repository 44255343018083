import React, { useContext } from 'react';

import './CartPlanDescription.style.scss';
import PropTypes from 'prop-types';

import { CartContext } from '../../contexts/cartContext';
import currencyFormatter from '../../utils/currencyFormatter';

const CartPlanDescription = ({ plan }) => {
  const { data, isPlanWith } = useContext(CartContext);
  const { planPrice, planQuantity, posPrice } = data;

  const planPriceCurrency = currencyFormatter.format(planPrice);
  const posPriceCurrency = currencyFormatter.format(posPrice);
  const planCurrencyTotal = currencyFormatter.format(
    planPrice * planQuantity + (isPlanWith.POS ? posPrice * planQuantity : 0),
  );

  return (
    <div className="cartPlan">
      <div className="cartPlan__planDetailRow cartPlan__planTotalsSpacing">
        <span className="cartPlan__planProductsTitle">
          Descrição dos produtos
        </span>
      </div>
      <div className="cartPlan__planDetailRow cartPlan__planDetailRow__first cartPlan__planTotalsSpacing">
        <span className="cartPlan__planTotalPrice cartPlan__planTotalPrice--small cartPlan__planName">
          {plan.baseName || plan.name}
        </span>
        <div className="cartPlan__planTotalPrice__container">
          <span className="cartPlan__planTotalQuantity">{planQuantity}</span>
          <span className="cartPlan__planTotalPrice cartPlan__planTotalPrice--small">
            {planPriceCurrency}/mês
          </span>
        </div>
      </div>
      {plan.addon && (
        <div className="cartPlan__planDetailRow cartPlan__planTotalsSpacing cartPlan__planAddon">
          <span className="cartPlan__planTotalPrice cartPlan__planTotalPrice--small cartPlan__planName">
            {plan.addon.productName}
            <span className="disclaimer">
              Cobrado a parte pela Getnet mensalmente
              <br /> dos seus recebíveis.
            </span>
          </span>
          <div className="cartPlan__planTotalPrice__container cartPlan__planTotalPrice__container__addon">
            <span className="cartPlan__planTotalQuantity">{planQuantity}</span>
            <span className="cartPlan__planTotalPrice cartPlan__planTotalPrice--small">
              {posPriceCurrency}/mês
            </span>
          </div>
        </div>
      )}
      <div className="cartPlan__planDetailRow cartPlan__planTotalsSpacing cartPlan__planTotalsSpacing--large">
        <span className="cartPlan__planTotalPrice">Total</span>
        <span className="cartPlan__planTotalPrice">
          {planCurrencyTotal}/mês
        </span>
      </div>
    </div>
  );
};

CartPlanDescription.propTypes = {
  plan: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    baseName: PropTypes.string,
    value: PropTypes.number.isRequired,
    addon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      tooltip: PropTypes.string.isRequired,
      quantifier: PropTypes.string,
      productName: PropTypes.string,
    }),
  }).isRequired,
};

export default CartPlanDescription;
