import React from 'react';
import './Whatsapp.styles.scss';

const Whatsapp = () => {
  const openWppContact = () => {
    window.open(
      'https://api.whatsapp.com/send/?phone=551149330330&text=Envie+esta+mensagem+para+iniciar+atendimento',
      '_blank',
    );
  };

  return (
    <button type="button" className="wpp-btn" onClick={openWppContact}>
      <img
        src="https://eye-public.s3.sa-east-1.amazonaws.com/site/wpp-icon.svg"
        alt="Whatsapp ícone"
      />
    </button>
  );
};

export default Whatsapp;
