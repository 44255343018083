import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import cx from 'clsx';
import PropTypes from 'prop-types';

import './CartPlanTooltip.styles.scss';

const CartPlanTooltip = ({ img, alt, tooltip, small, className }) => {
  const imgRef = useRef(null);

  const handleImgClick = () => {
    ReactTooltip.show(imgRef.current);
    setTimeout(() => {
      ReactTooltip.hide(imgRef.current);
    }, 2000);
  };

  return (
    <div
      onClick={handleImgClick}
      role="presentation"
      className={cx('cartPlanTooltip', className)}
    >
      <img
        ref={imgRef}
        src={img}
        data-tip={tooltip}
        alt={alt}
        className={
          small
            ? 'cartPlanTooltip__smallCartPlanItem'
            : 'cartPlanTooltip__cartPlanItem'
        }
      />
      <ReactTooltip
        effect="solid"
        place="top"
        backgroundColor="#959595"
        textColor="#ffffff"
        className="cartPlanTooltip__cartPlanTooltip"
        insecure={false}
        clickable={false}
      />
    </div>
  );
};

CartPlanTooltip.defaultProps = {
  small: false,
  className: undefined,
};

CartPlanTooltip.propTypes = {
  img: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  small: PropTypes.bool,
  className: PropTypes.string,
};

export default CartPlanTooltip;
