import React, { useMemo, useState } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import CartButton from '../CartButton';
import CartCheckbox from '../CartCheckbox';
import POSTermsModal from '../POSTermsModal';
import Select from '../Select';
import { taxesOptions, taxesReceivalParcel } from './taxesData';
import './CartPOS.styles.scss';

const validationSchema = Yup.object().shape({
  anticipationMethod: Yup.string().required('Campo obrigatório'),
  parcelsQnt: Yup.string().required('Campo obrigatório'),
});

const CartPOS = ({
  onBack,
  onFormSubmit,
  disabled,
  anticipationMethod,
  parcelsQnt,
  terms,
}) => {
  const formik = useFormik({
    initialValues: {
      anticipationMethod,
      terms,
      parcelsQnt,
    },
    validationSchema,
    onSubmit: onFormSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const { values, setFieldValue, handleSubmit, isSubmitting } = formik;

  const [showTermsModal, setShowTermsModal] = useState(false);

  const toNum = (val) => {
    return parseInt(val, 10);
  };

  const parcelTaxValue = useMemo(() => {
    return taxesReceivalParcel.find(
      (parcel) => parcel.id === toNum(values.parcelsQnt),
    )[toNum(values.anticipationMethod) === 1 ? 'total2x' : 'totalByParcel'];
  }, [values.parcelsQnt, values.anticipationMethod]);

  const creditInSightTax = useMemo(() => {
    return toNum(values.anticipationMethod) === 1 ? '4,72%' : '2,79%';
  }, [values.anticipationMethod]);

  const handleParcelsChange = (e) => {
    setFieldValue('parcelsQnt', e.target.value);
  };

  const handleCreditChange = (e) => {
    setFieldValue('anticipationMethod', e.target.value);
  };

  const handleTermsChange = () => {
    setFieldValue('terms', !values.terms);
  };

  const handleTermsModal = () => {
    setShowTermsModal((prev) => !prev);
  };

  const anticipationOptions = [
    {
      id: '1',
      value: 1,
      label: '2 dias',
    },
    {
      id: '2',
      value: 2,
      label: 'Conforme parcelas',
    },
  ];

  const termSection = (
    <span>
      Concordo com todos os
      <button
        className="cartPos__terms"
        type="button"
        onClick={handleTermsModal}
      >
        termos e condições
      </button>
    </span>
  );

  return (
    <>
      <div className="cartPos">
        <div className="cartPos__leftContainer">
          <div className="cartPos__imageContainer">
            <img
              src="https://assets.eye.com.br/getnet.png"
              alt="GetNet maquininha"
            />
            <img
              src="https://assets.eye.com.br/badge_best_tax.png"
              alt="badge"
              className="cartPos__itemBadge"
            />
          </div>
          <div className="cartPos__textContainer">
            <span className="cartPos__title">POS Digital Getnet</span>
            <span className="cartPos__rent">
              Aluguel da máquina é cobrado <br /> a parte pela Getnet dos
              recebíveis.
            </span>
            <span className="cartPos__description">Descrição da oferta</span>
            <span className="cartPos__descriptionList">
              &bull; Frete grátis (Envio pela Getnet) <br />
              &bull; Sem multa para cancelamento. <br />
              &bull; Prazo de entrega: Até 10 dias
            </span>
          </div>
        </div>
        <div className="cartPos__taxesContainer">
          <span className="cartPos__selectorLabel">
            Receber as vendas no crédito em
          </span>
          <Select
            selected={values.anticipationMethod}
            onChange={handleCreditChange}
            options={anticipationOptions}
            disabled={disabled}
          />
          <span className="cartPos__taxesItemTitle">
            Taxas para recebimento*
          </span>
          <div className="cartPos__taxesItemContainer">
            <div className="cartPos__taxesItem">
              <h3>Débito à vista</h3>
              <p>1,39%</p>
            </div>
            <div className="cartPos__taxesItem">
              <h3>Crédito à vista</h3>
              <p>{creditInSightTax}</p>
            </div>
            <div className="cartPos__taxesItem">
              <Select
                selected={values.parcelsQnt}
                onChange={handleParcelsChange}
                options={taxesOptions}
                disabled={disabled}
              />
              <p>{parcelTaxValue}</p>
            </div>
            <span className="cartPos__taxesWarning">
              *Taxas aplicáveis para visa e mastercard. Para antecipação será
              adicionado a taxa de 1.99% ao mês.
            </span>
          </div>
        </div>
      </div>
      <div className="cartPos__checkbox">
        <CartCheckbox
          inputId="acceptCheck"
          label={termSection}
          checked={values.terms}
          onChange={handleTermsChange}
        />
      </div>
      <div className="cartForm__buttonsContainer">
        <CartButton outlined onClick={onBack(values)}>
          ANTERIOR
        </CartButton>
        <CartButton onClick={handleSubmit} disabled={isSubmitting} large>
          PRÓXIMO
        </CartButton>
      </div>
      {showTermsModal && <POSTermsModal onClose={handleTermsModal} />}
    </>
  );
};

CartPOS.defaultProps = {
  disabled: false,
  terms: false,
  anticipationMethod: 2,
  parcelsQnt: 2,
};

CartPOS.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  anticipationMethod: PropTypes.number,
  terms: PropTypes.bool,
  parcelsQnt: PropTypes.number,
};

export default CartPOS;
