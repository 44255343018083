export const taxesOptions = [
  {
    id: 0,
    value: 2,
    label: 'Crédito 2x parcelas',
  },
  {
    id: 1,
    value: 3,
    label: 'Crédito 3x parcelas',
  },
  {
    id: 2,
    value: 4,
    label: 'Crédito 4x parcelas',
  },
  {
    id: 3,
    value: 5,
    label: 'Crédito 5x parcelas',
  },
  {
    id: 4,
    value: 6,
    label: 'Crédito 6x parcelas',
  },
  {
    id: 5,
    value: 7,
    label: 'Crédito 7x parcelas',
  },
  {
    id: 6,
    value: 8,
    label: 'Crédito 8x parcelas',
  },
  {
    id: 7,
    value: 9,
    label: 'Crédito 9x parcelas',
  },
  {
    id: 8,
    value: 10,
    label: 'Crédito 10x parcelas',
  },
  {
    id: 9,
    value: 11,
    label: 'Crédito 11x parcelas',
  },
  {
    id: 10,
    value: 12,
    label: 'Crédito 12x parcelas',
  },
];

export const taxesReceivalParcel = [
  {
    id: 2,
    total2x: '7,07%',
    totalByParcel: '4,19%',
  },
  {
    id: 3,
    total2x: '8,05%',
    totalByParcel: '4,19%',
  },
  {
    id: 4,
    total2x: '9,05%',
    totalByParcel: '4,19%',
  },
  {
    id: 5,
    total2x: '10,06%',
    totalByParcel: '4,19%',
  },
  {
    id: 6,
    total2x: '11,09%',
    totalByParcel: '4,19%',
  },
  {
    id: 7,
    total2x: '12,13%',
    totalByParcel: '4,19%',
  },
  {
    id: 8,
    total2x: '13,18%',
    totalByParcel: '4,19%',
  },
  {
    id: 9,
    total2x: '14,25%',
    totalByParcel: '4,19%',
  },
  {
    id: 10,
    total2x: '15,33%',
    totalByParcel: '4,19%',
  },
  {
    id: 11,
    total2x: '16,42%',
    totalByParcel: '4,19%',
  },
  {
    id: 12,
    total2x: '17,19%',
    totalByParcel: '4,19%',
  },
];
