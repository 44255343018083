import React from 'react';
import InputMask from 'react-input-mask';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import './CartField.styles.scss';

const CartField = ({
  inputId,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  error,
  mask,
  maxLength,
  disabled,
}) => {
  return (
    <div className={clsx('cartField', `cartField--${inputId}`, { disabled })}>
      <label htmlFor={inputId} className="cartField__label">
        {label}
      </label>
      {mask ? (
        <InputMask
          id={inputId}
          mask={mask}
          maskChar={null}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              placeholder={placeholder}
              type="text"
              className={clsx('cartField__input', {
                error: !!error,
              })}
              disabled={disabled}
            />
          )}
        </InputMask>
      ) : (
        <input
          id={inputId}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          type="text"
          maxLength={maxLength}
          disabled={disabled}
          className={clsx('cartField__input', {
            error: !!error,
          })}
        />
      )}
      <span className="cartField__error">{error}</span>
    </div>
  );
};

CartField.defaultProps = {
  mask: null,
  error: null,
  maxLength: undefined,
  onFocus: undefined,
  disabled: false,
};

CartField.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.string,
  mask: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
};

export default CartField;
