import React from 'react';

import PropTypes from 'prop-types';

import Portal from '../Portal';
import './Overlay.styles.scss';

const Overlay = ({ children }) => {
  return (
    <Portal>
      <div className="eye-overlay">{children}</div>
    </Portal>
  );
};

Overlay.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Overlay;
