export const LPA = 'a-taxa';
export const LPB = 'b-prod';
export const LPC = 'c-price';

export const prices = {
  default: {
    pdvPrice: 49.9,
    fiscalPrice: 24.95,
    posPrice: 39.9,
  },
  [LPA]: {
    pdvPrice: 99.9,
    fiscalPrice: 49.95,
    posPrice: 39.9,
  },
  [LPB]: {
    pdvPrice: 99.9,
    fiscalPrice: 49.95,
    posPrice: 39.9,
  },
  [LPC]: {
    pdvPrice: 49.9,
    fiscalPrice: 24.95,
    posPrice: 39.9,
  },
};
