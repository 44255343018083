import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import './Button.styles.scss';

const Loading = () => {
  return (
    <div className="spinner">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="7" strokeWidth="2" />
      </svg>
    </div>
  );
};

const CartButton = ({
  id,
  onClick,
  children,
  outlined,
  disabled,
  large,
  isLoading,
}) => {
  return (
    <button
      id={id}
      className={clsx('cart-button', {
        outline: outlined,
        'cart-button--large': large,
        'cart-button--loading': isLoading,
      })}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {isLoading && <Loading />}
    </button>
  );
};

CartButton.defaultProps = {
  outlined: false,
  disabled: false,
  large: false,
  isLoading: false,
  id: undefined,
};

CartButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  isLoading: PropTypes.bool,
  id: PropTypes.string,
};

export default CartButton;
