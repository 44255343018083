import React from 'react';

import * as PropTypes from 'prop-types';

import './POSTermsModal.styles.scss';

const POSModal = ({ onClose }) => {
  return (
    <div className="POSTermsModal">
      <div className="POSTermsModal__modal">
        <div className="POSTermsModal__header">
          <span className="POSTermsModal__headerTitle">Termos e condições</span>
          <button
            type="button"
            className="POSTermsModal__headerClose"
            onClick={onClose}
          >
            <img
              src="https://assets.eye.com.br/close-outlined.svg"
              alt="close"
            />
          </button>
        </div>
        <p className="POSTermsModal__text">
          Oferta de desconto no POS Digital de R$129,90 por R$39,90, limitada a
          6 máquinas. Acima desta quantidade, será cobrado o valor de R$ 129,90
          por máquina. É necessário estar adimplente e manter ativa a licença
          Eye; O tempo de vigência da oferta é de prazo indeterminado a partir
          da data de aceite; Caso não cumpra com as contrapartidas da oferta não
          ganhará o subsídio no mês. Porém continuará a participar da promoção
          até o fim da vigência; É necessário estar adimplente; Oferta não
          cumulativa com demais ofertas de aluguel; A oferta e/ou suas condições
          comerciais podem ser encerradas ou alteradas a qualquer momento
          mediante comunicado prévio aos clientes.
        </p>

        <button
          type="button"
          className="POSTermsModal__modalButton"
          onClick={onClose}
        >
          OK
        </button>
      </div>
    </div>
  );
};

POSModal.defaultProps = {};

POSModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default POSModal;
