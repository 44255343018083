/* eslint-disable max-len */
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';

import cardValidator from 'card-validator';
import { useFormik, FormikProvider } from 'formik';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import './Forms.styles.scss';

import { CartContext } from '../../contexts/cartContext';
import http from '../../utils/axios';
import dayjs from '../../utils/dayjs';
import { isBrowser } from '../../utils/isBrowser';
import CartButton from '../CartButton';
import CartField from '../CartField';
import CartPlanDescription from '../CartPlanDescription';
import CreditCard from '../CreditCard';
import Overlay from '../Overlay';

const Loading = () => {
  return (
    <div className="spinner">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="7" strokeWidth="4" />
      </svg>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  payForm: Yup.string().required(),
  ccNumber: Yup.string()
    .test(
      'validate-card-number',
      'Número do cartão inválido',
      function validateCardNumber(value) {
        const validation = cardValidator.number(value);

        return validation.isValid;
      },
    )
    .test(
      'validate-card-number',
      'Bandeira do cartão não suportada',
      function validateCardNumber(value) {
        const validation = cardValidator.number(value);

        return (
          validation.isValid &&
          ['visa', 'american-express', 'diners-club', 'mastercard', 'elo'].some(
            (allowedType) => allowedType === validation?.card?.type,
          )
        );
      },
    )
    .required('Campo obrigatório'),
  ccName: Yup.string()
    .trim()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s ]*)$/g,
      'Nome pode conter apenas letras.',
    )
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)) (?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      'Escreva um nome válido.',
    )
    .required('Campo obrigatório'),
  ccCode: Yup.string()
    .test('validate-cvv', 'Código inválido', function validateCvv(value) {
      const { ccNumber } = this.parent;
      const cardNumberValidation = cardValidator.number(ccNumber);

      const validation = cardValidator.cvv(
        value,
        cardNumberValidation?.card?.code?.size || 3,
      );

      return validation.isValid;
    })
    .required('Campo obrigatório'),
  ccExpiration: Yup.string()
    .test(
      'validate-expiration-date',
      'Expiração inválida',
      function validateExpirationDate(value) {
        const day = dayjs(value, 'MM/YY');
        const formattedValue = day.format('MM/YY');

        if (dayjs().isAfter(day)) {
          return false;
        }

        return formattedValue === value;
      },
    )
    .required('Campo obrigatório'),
});

const PaymentCheckBox = ({
  label,
  value,
  chosenMethod,
  handleChange,
  disabled,
}) => {
  const changePayment = () => {
    handleChange(value);
  };

  return (
    <button
      type="button"
      className={`payment-method ${chosenMethod === value && 'active'}`}
      onClick={changePayment}
      disabled={disabled}
    >
      <div className="check-box">
        <div className="check" />
      </div>
      <p className="label"> {label}</p>
    </button>
  );
};

PaymentCheckBox.defaultProps = {
  disabled: false,
};

PaymentCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  chosenMethod: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const PaymentForm = ({
  initialData,
  onFormSubmit,
  onBack,
  showOverlay,
  plan,
  onCopy,
  disabled,
}) => {
  const { data, patchData } = useContext(CartContext);
  const { isLeadOnFlow, existentLead, leadUuid } = data;
  const [focused, setFocused] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('credit-card');

  useEffect(() => {
    if (isBrowser && leadUuid) {
      window.dataLayer.push({
        event: 'create-lead',
      });
    }

    if (isBrowser && !existentLead) {
      window.dataLayer.push({
        event: 'update-lead',
      });
    }
  }, [isLeadOnFlow, existentLead, leadUuid]);

  const intervalRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      payForm: initialData?.payForm || 'cc',
      ccNumber: initialData?.ccNumber || '',
      ccName: initialData?.ccName || '',
      ccCode: initialData?.ccCode || '',
      ccExpiration: initialData?.ccExpiration || '',
    },
    validationSchema,
    onSubmit: (values, formikBag) => {
      onFormSubmit(values, formikBag);
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = formik;

  const handleFieldChange = (field) => (e) =>
    setFieldValue(field, e.target.value);
  const handleFieldFocus = (field) => () => setFocused(field);

  const options = [
    {
      value: 'credit-card',
      label: 'Cartão de Crédito',
    },
    {
      value: 'pix',
      label: 'PIX',
    },
  ];

  const checkSubscriptionStatus = useCallback(async () => {
    try {
      const response = await http
        .get(`/subscriptions/subscription/payment/${data.subscriptionId}`)
        .then(({ data: responseData }) => responseData);

      if (response.some((invoice) => invoice.status === 'paid')) {
        const thankYouByPlan = {
          P001: '/carrinho/obrigado-pdv',
          P002: '/carrinho/obrigado-pdv-fiscal',
          P003: '/carrinho/obrigado-pdv',
        };

        navigate(thankYouByPlan[data.planId], {
          state: { finished: true },
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [data.subscriptionId, data.planId]);

  useEffect(() => {
    if (data.subscriptionId && !intervalRef.current) {
      intervalRef.current = setInterval(checkSubscriptionStatus, 30000);
    }

    return () => clearInterval(intervalRef.current);
  }, [data.subscriptionId, checkSubscriptionStatus]);

  const getPixData = async () => {
    const response = await http
      .post(`/subscriptions/lead/${data.uuid}/subscription`, {
        method: 'pix',
      })
      .then(({ data: responseData }) => responseData);

    return response;
  };

  const handlePaymentChange = async (value) => {
    setPaymentMethod(value);

    if (value === 'pix' && !data.qrCode) {
      setIsLoading(true);

      try {
        const response = await getPixData();

        patchData({
          subscriptionId: response.subscriptionId,
          qrCode: response.qrCode,
          qrCodeText: response.qrCodeText,
        });

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
  };

  const renderPaymentMethodForm = () => {
    if (isLoading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    if (paymentMethod === 'pix') {
      return (
        <div className="cartForm__pixContainer">
          <div className="cartForm__qrCode">
            <img
              src={data.qrCode}
              alt="QrCode para pagamento"
              width="205px"
              height="205px"
            />
          </div>
          <div className="cartForm__pixCode">
            <h3>PIX Copia e cola</h3>
            <p>
              Para completar sua compra, leia o QRcode ou copie o código abaixo
              e cole no seu app do banco usando a função PIX copia e cola.
            </p>
            <textarea readOnly value={data.qrCodeText} />
          </div>
        </div>
      );
    }

    return (
      <div className="cartForm__card-fields">
        <CreditCard
          cvc={values.ccCode}
          expiry={values.ccExpiration}
          focused={focused}
          name={values.ccName}
          number={values.ccNumber}
          locale={{
            valid: 'Validade',
          }}
          placeholders={{
            name: 'Nome no cartão',
          }}
        />
        <div className="cartForm__card-fields__fields">
          <div className="cartForm__fieldRow">
            <div className="cartForm__fieldRow__bigField">
              <CartField
                inputId="ccNumber"
                label="Número do cartão"
                value={values.ccNumber}
                onChange={handleFieldChange('ccNumber')}
                onBlur={handleBlur}
                onFocus={handleFieldFocus('number')}
                placeholder="Número do cartão"
                mask="9999 9999 9999 9999"
                error={touched.ccNumber && errors.ccNumber}
                disabled={disabled}
              />
            </div>
            <div className="cartForm__fieldRow__smallField">
              <CartField
                inputId="ccExpiration"
                label="Vencimento"
                value={values.ccExpiration}
                onBlur={handleBlur}
                onChange={handleFieldChange('ccExpiration')}
                onFocus={handleFieldFocus('expiry')}
                placeholder="MM/YY"
                mask="99/99"
                error={touched.ccExpiration && errors.ccExpiration}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="cartForm__fieldRow">
            <div className="cartForm__fieldRow__bigField">
              <CartField
                inputId="ccName"
                label="Nome no cartão"
                value={values.ccName}
                onBlur={handleBlur}
                onChange={handleFieldChange('ccName')}
                onFocus={handleFieldFocus('name')}
                placeholder="Nome no cartão"
                error={touched.ccName && errors.ccName}
                disabled={disabled}
              />
            </div>
            <div className="cartForm__fieldRow__smallField">
              <CartField
                inputId="ccCode"
                label="CVC/CVV"
                value={values.ccCode}
                onBlur={handleBlur}
                onChange={handleFieldChange('ccCode')}
                onFocus={handleFieldFocus('cvc')}
                placeholder="CVC"
                mask="9999"
                error={touched.ccCode && errors.ccCode}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <FormikProvider value={formik}>
      <div className="cartForm__header">
        <span className="cartForm__formTitle">Pagamento</span>
        <div className="cartForm__cartPlanDescription">
          <CartPlanDescription plan={plan} />
        </div>
        <span className="cartForm__formTitle cartForm__formTitle__subtitle">
          Selecione a forma de pagamento
        </span>
        <div className="cartForm__paymentMethods">
          {options.map((opt) => {
            return (
              <PaymentCheckBox
                label={opt.label}
                value={opt.value}
                chosenMethod={paymentMethod}
                handleChange={handlePaymentChange}
                disabled={disabled}
              />
            );
          })}
        </div>
      </div>
      <div className="cartForm">
        {renderPaymentMethodForm()}
        <div className="cartForm__buttonsContainer">
          <CartButton
            outlined
            onClick={onBack}
            disabled={isSubmitting || isLoading}
          >
            ANTERIOR
          </CartButton>
          {paymentMethod === 'pix' ? (
            <CartButton
              onClick={onCopy}
              disabled={isSubmitting || isLoading}
              large
            >
              <img src="https://assets.eye.com.br/copy.svg" alt="copy" />
              COPIAR CÓDIGO
            </CartButton>
          ) : (
            <CartButton
              onClick={handleSubmit}
              disabled={disabled || !isValid || isSubmitting}
              isLoading={isSubmitting}
              large
            >
              PRÓXIMO
            </CartButton>
          )}
        </div>
      </div>
      {showOverlay && (
        <Overlay>
          Aguarde, estamos processando o seu pagamento...
          <br />
          <br />
          <em>Não recarregue ou saia desta página.</em>
        </Overlay>
      )}
    </FormikProvider>
  );
};

PaymentForm.defaultProps = {
  disabled: false,
};

PaymentForm.propTypes = {
  initialData: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool.isRequired,
  plan: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    baseName: PropTypes.string,
    value: PropTypes.number.isRequired,
    addon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      tooltip: PropTypes.string.isRequired,
      quantifier: PropTypes.string,
      productName: PropTypes.string,
    }),
  }).isRequired,
  onCopy: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PaymentForm;
